<template>
  <v-row>
    <v-col cols="12">
      <v-card color="primary" dark>
        <!-- FILTROS -->
        <v-card-title class="px-4 pb-0">
          <v-col cols="12" class="pa-0">
            <v-row class="px-1 py-0">
              <v-col class="px-2">
                <v-select label="Período" filled dense :items="periodos" item-text="data" item-value="data"
                  :disabled="carregando || carregandoTotais || carregandoTotais2" v-model="busca.periodo.data"
                  hide-details @change="listarVendas(), listarPeriodos()"></v-select>
              </v-col>
              <v-col class="px-2">
                <v-select label="Filial" filled dense :items="filiaisFiltro" item-text="filial" item-value="idfilial"
                  :disabled="carregando || carregandoTotais || carregandoTotais2" v-model="busca.idempresa" hide-details
                  clearable @change="listarVendas()"></v-select>
              </v-col>
              <!--
              <v-col class="px-2" v-show="!busca.idempresa">
                <v-select label="Regional" filled dense :items="regionais" item-text="regional" item-value="idregional"
                  :disabled="carregando || carregandoTotais || carregandoTotais2" v-model="busca.idregional"
                  hide-details clearable @change="listarVendas()"></v-select>
              </v-col> -->
              <v-col class="px-2" v-show="!busca.idempresa">
                <v-select label="Seção" filled dense :items="secoes" item-text="descr" item-value="idsecao"
                  :disabled="carregando || carregandoTotais || carregandoTotais2" v-model="busca.idsecao" hide-details
                  clearable @change="listarVendas()"></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="py-3 pt-4">
            <v-row v-if="dataUltAtualizacao">
              <v-spacer /><span class="text-caption">Atualizado em: {{ dataUltAtualizacao }}</span>
            </v-row>
            <v-row v-else>
              <v-spacer />
              <v-progress-circular class="mt-1 mr-1" indeterminate color="white" :size="12"></v-progress-circular>
              <span class="text-caption font-italic">Carregando...</span>
            </v-row>
          </v-col>
        </v-card-title>

        <!-- DATA INFO-->
        <v-card-text class="pt-0">
          <v-skeleton-loader light v-if="carregando" type="table-tbody" />
          <v-simple-table light v-else-if="vendasLojas[0] && !carregando" fixed-header height="50vh">
            <thead ref="thead">
              <tr class="border-line-head">
                <th class="fixed-column-header white--text text-center" nowrap>
                  <v-row class="pb-0" no-gutters>
                    <v-col cols="3" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                      <div>
                        <v-tooltip bottom color="primary" class="text-no-wrap">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="carregandoTotais" v-bind="attrs" v-on="on" @click="dialog = true"
                              elevation="0" class="primary mt-n1 ml-n4 pa-0" x-small style="border-radius: 0px;"
                              height="39px" dark><v-icon class="mt-1">mdi-arrow-expand-all</v-icon></v-btn>
                          </template>
                          <span>Visualizar em tela cheia</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="9" class="pa-0" style="display: flex; justify-content: center; align-items: center;">
                      <div class="mr-11">
                        DATA
                      </div>
                    </v-col>
                  </v-row>
                </th>
                <template v-for="l in lojas">
                  <th class="text-center white--text" :key="l" nowrap v-if="validarNumero(l)">{{ objNomeLojas[l] }}</th>
                </template>
                <th class="text-center white--text barra-total-left" nowrap>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(u, i) in vendasLojas" :key="i" class="border-line">
                <td class="px-2 text-center fixed-column" nowrap> {{ u.data | formataData }} </td>
                <template v-for="l in lojas">
                  <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="u.lojas[objLojas[l]] < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ u.lojas[objLojas[l]] || 0 |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </template>
                <td class="px-2 text-right barra-total-left column-totais" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="u.total_venda_bruta_dia < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.total_venda_bruta_dia || 0 |
                      formataDinheiroTabela
                      }} </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="vendasLojas[0] && vendasLojas[0]">
              <tr v-if="totalizadores.vendas.totaloja[0]" class="barra-total-top">
                <td colspan="100%" style="height: 3px !important"></td>
              </tr>
              <!--
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Líquida</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendaliquida['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendaliquida['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.variacoes[0].totalvendaliquida < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.variacoes[0].totalvendaliquida ||
                      0 |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr>
              Total Venda Líquida -->
              <tr v-if="totalizadores.vendas.totaloja[0]" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.vendas.totaloja[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.vendas.totaloja[0].lojas['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.vendas.totalvendaLiquida < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.vendas.totalvendaLiquida || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Total Venda Bruta -->
              <tr v-if="totalizadores.vendas.perclojas[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Geral</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                  :style="totalizadores.vendas.perclojas[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.perclojas[0].lojas['loja' + n].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap
                  :style="totalizadores.vendas.perclojas[0].totalparticipacaogeral < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.perclojas[0].totalparticipacaogeral.toFixed(2) || 0 }}%
                </td>
              </tr> <!--  % Partic. Geral -->
              <!--
              <tr v-if="totalizadores.vendas.percpartregionais[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                  :style="totalizadores.vendas.percpartregionais[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.percpartregionais[0].lojas['loja' + n].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap
                  :style="totalizadores.vendas.percpartregionais[0].totalpartregional < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.vendas.percpartregionais[0].totalpartregional.toFixed(2) || 0 }}%
                </td>
              </tr>
              % Partic. Região -->
              <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Meta de Venda - Mês</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasMes[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasMes[0].lojas['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.metasMes[0].totalmeta < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasMes[0].totalmeta || 0 |
                      formataDinheiroTabela
                      }}</span>
                  </div>
                </td>
              </tr> <!--  Meta de venda - Mes -->
              <tr v-if="totalizadores.metasDia[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Meta de Venda Acumulada</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasDia[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasDia[0].lojas['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.metasDia[0].totalmeta < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasDia[0].totalmetadia || 0 |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr> <!--  Meta de venda Acumulada -->
              <tr v-if="totalizadores.metasVariacao[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">% Variação de Meta</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].lojas['loja' + n])">
                    {{ totalizadores.metasVariacao[0].lojas['loja' + n].toFixed(2) || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].TotalMetaVariacao)">
                    {{ totalizadores.metasVariacao[0].TotalMetaVariacao.toFixed(2) || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  % Variação de Meta -->
              <!--
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compvariacao">Venda Liq. Ano Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] || 0 | formataDinheiroTabela
                      }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.variacoes[0].totalvendabrutaanoanterior < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span> {{
                      totalizadores.variacoes[0].totalvendabrutaanoanterior || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>   Venda Liq. Ano Ant. -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação A.A. </td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label
                    :class="coresVarariacoes(totalizadores.variacoes[0].variacaonominalperc['loja' + n])">
                    {{ totalizadores.variacoes[0].variacaonominalperc['loja' + n].toFixed(2) || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacaonominal)">
                    {{ totalizadores.variacoes[0].totalpercvariacaonominal.toFixed(2) || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  % Var. A.A. -->
              <!--
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] || 0 | formataDinheiroTabela
                      }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.variacoes[0].totalvendabrutamesanterior < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>
                      {{ totalizadores.variacoes[0].totalvendabrutamesanterior || 0 |
                        formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>   Venda Liq. Mês Ant. -->
              <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label
                    :class="coresVarariacoes(totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n])">
                    {{ totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n].toFixed(2) || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :color="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacao)">
                    {{ totalizadores.variacoes[0].totalpercvariacao.toFixed(2) || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  % Var. Mês Ant. -->
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <!--
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Vendas de Obsoletos (R$)
                      <v-icon v-bind="attrs" v-on="on" small color="red" class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Apoio a decisão</span>
                  </v-tooltip>
                </td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.vendasObsoletos[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.vendasObsoletos[0].lojas['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadores.vendasObsoletos[0].total_venda_obsoleto || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>   Vendas de Obsoletos (R$) -->
              <!--
              <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margens['loja' + n])">
                    {{ totalizadores.vendasObsoletos[0].margens['loja' + n].toFixed(2) || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margem_venda_obsoleto)">
                    {{ totalizadores.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) || 0 }}%
                  </v-chip>
                </td>
              </tr>   Margem Obsoletos (%) -->
              <!--
              <tr v-if="totalizadores.estoqueObsoletos[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>

                <template v-for="l in lojas">
                  <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharObsoletos(l, objNomeLojas[l])"
                        class="botao_detalhe2 px-2">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                          <span>R$</span><span>{{ totalizadores.estoqueObsoletos[0].lojas['loja' + l] || 0 |
                            formataDinheiroTabela }} </span>
                        </div>
                      </v-btn>
                    </v-hover>
                  </td>
                </template>

                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.estoqueObsoletos[0].total_estoque || 0 | formataDinheiro }}
                </td>
              </tr>   Estoque de Obsoletos - Em desenvolvimento ...-->

              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">Total Devoluções (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.devolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.devolucao[0].devolucao['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.devolucao[0].totaldevolucao || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Total Devoluções (R$) -->
              <tr v-if="totalizadores.perDevolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                  :style="totalizadores.perDevolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.perDevolucao[0].devolucao['loja' + n].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <!--                  <v-chip small label :color="totalizadores.desconto[0].totaldescontoconcedido<0?'error':''">-->
                  {{ totalizadores.perDevolucao[0].totaldevolucao.toFixed(2) || 0 }}%
                  <!--                  </v-chip>-->
                </td>
              </tr> <!--  Total Devoluções (%) -->
              <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <!--
              <tr v-if="totalizadores.metasDesconto[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Metas de Descontos (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.metasDesconto[0].metadescontoreal['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.metasDesconto[0].metadescontoreal['loja' + n] || 0 | formataDinheiroTabela
                      }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.metasDesconto[0].metatotalreal < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.metasDesconto[0].metatotalreal || 0
                      |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>   Metas de Descontos (R$) -->
              <!--
              <tr v-if="totalizadores.metasPerDesconto[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Metas de Descontos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                  :style="totalizadores.metasPerDesconto[0].metadesconto['loja' + n] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.metasPerDesconto[0].metadesconto['loja' + n].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.metasPerDesconto[0].metatotal.toFixed(2) || 0 }}%
                </td>
              </tr>   Metas de Descontos (%)  -->
              <tr v-if="totalizadores.desconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.desconto[0].valordesconto['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.desconto[0].valordesconto['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.totalvendabrutageral < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.desconto[0].totaldesconto || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Total Desconto -->
              <tr v-if="totalizadores.desconto[0] && totalizadores.metasPerDesconto[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresPerDesconto(['loja' + n])">
                    {{ totalizadores.desconto[0].descontoconcedido['loja' + n].toFixed(2) || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label
                    :class="totalizadores.desconto[0].totaldescontoconcedido > totalizadores.metasPerDesconto[0].metatotal ? 'red white--text' : 'blue darken-2 white--text'">
                    {{ totalizadores.desconto[0].totaldescontoconcedido.toFixed(2) || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  % Desconto -->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <tr v-if="totalizadores.margemMeta != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                <td v-for="(chave) in Object.keys(totalizadores.margemMeta).filter(chave => chave !== 'TOTAL' && chave !== '42')" :key="chave" class="px-2 text-center" nowrap
                  :style="totalizadores.margemMeta[chave] < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.margemMeta[chave] || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap
                  :style="totalizadores.margemMeta.TOTAL < 0 ? 'color:#F22233;' : ''">
                  {{ totalizadores.margemMeta.TOTAL || 0 }}%
                </td>
              </tr> <!--  Margem Meta ...-->
              <tr v-if="totalizadores.margemAcumulada != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Margem Realizada Fat.
                      <v-icon v-bind="attrs" v-on="on" small color="red darken-3"
                              class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Margem baseada no Faturamento</span>
                  </v-tooltip>
                </td>
                <td v-for="(chave) in Object.keys(totalizadores.margemAcumulada).filter(chave => chave !== 'TOTAL' && chave !== '42' )" :key="chave" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margemAcumulada[chave])">
                    {{ totalizadores.margemAcumulada[chave] || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margemAcumulada.TOTAL)">
                    {{ totalizadores.margemAcumulada.TOTAL || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  Margem Realizada Fat. -->
              <tr v-if="totalizadores.margemVenda != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Margem Realizada Apoio
                      <v-icon v-bind="attrs" v-on="on" small color="red darken-3"
                              class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Margem sobre a Venda</span>
                  </v-tooltip>
                </td>
                <td v-for="(chave) in Object.keys(totalizadores.margemVenda).filter(chave => chave !== 'TOTAL' && chave !== '42' )" :key="chave" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margemVenda[chave])">
                    {{ totalizadores.margemVenda[chave] || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margemVenda.TOTAL)">
                    {{ totalizadores.margemVenda.TOTAL || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  Margem Realizada sobre a venda -->
              <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].margemanopassado['loja' + n])">
                    {{ totalizadores.margem[0].margemanopassado['loja' + n].toFixed(2) || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margem[0].totalmargemlastyear)">
                    {{ totalizadores.margem[0].totalmargemlastyear.toFixed(2) || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  Margem Ano Anterior -->
              <tr v-if="totalizadores.margemFatAnoAnt != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Faturamento Ano Anterior</td>
                <td v-for="(chave) in Object.keys(totalizadores.margemFatAnoAnt).filter(chave => chave !== 'TOTAL' && chave !== '42' )" :key="chave" class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margemFatAnoAnt[chave])">
                    {{ totalizadores.margemFatAnoAnt[chave] || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-chip small label :class="coresMargem(totalizadores.margemFatAnoAnt.TOTAL)">
                    {{ totalizadores.margemFatAnoAnt.TOTAL || 0 }}%
                  </v-chip>
                </td>
              </tr> <!--  Margem Faturamento Ano Anterior -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Entrada Ajuste de Estoque</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] || 0 | formataDinheiroTabela
                      }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.entradasSaidasAvulsa[0].totalentrada < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadores.entradasSaidasAvulsa[0].totalentrada || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Entrada Avulsa -->
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saída Ajuste de Estoque</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] || 0 | formataDinheiroTabela
                      }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.entradasSaidasAvulsa[0].totalsaida < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalsaida
                      || 0
                      |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Saida Avulsa -->
              <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saldo de Ajustes</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] || 0 | formataDinheiroTabela
                      }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.entradasSaidasAvulsa[0].totalsaldo < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.entradasSaidasAvulsa[0].totalsaldo
                      || 0
                      |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Saldo -->
              <tr v-if="totalizadores.estoqueAnoAnt[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <tr v-if="totalizadores.estoqueAnoAnt[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.estoqueAnoAnt[0].totalestoqued1 < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoqueAnoAnt[0].totalestoqued1 ||
                      0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Estoque Ano Anterior -->
              <tr v-if="totalizadores.diasEstoqueFuturo[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.diasEstoqueFuturo[0].lojas['loja' + n] || 0 }} dias
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.diasEstoqueFuturo[0].totaldiasestoquefuturo || 0 }} dias
                </td>
              </tr> <!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
              <tr v-if="totalizadores.diasEstoqueReal[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.diasEstoqueReal[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.diasEstoqueReal[0].lojas['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.diasEstoqueReal[0].totaldiasestoquereal < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadores.diasEstoqueReal[0].totaldiasestoquereal || 0 |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr> <!--  Dias de Estoque - Real - Em desenvolvimento ...-->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].estoqued2['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].estoqued2['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.estoque[0].totalestoqued2 < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued2 || 0 |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr> <!--  Estoque Inicial -->
              <!--
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Movimentação</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].Movimentacoes['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].Movimentacoes['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.estoque[0].totalMovimentacoes < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalMovimentacoes || 0
                      |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr>   Movimentação -->
              <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                  <div :style="totalizadores.estoque[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''"
                    class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span>
                    <span>{{ totalizadores.estoque[0].estoqued1['loja' + n] || 0 | formataDinheiroTabela }}</span>
                  </div>
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadores.estoque[0].totalestoqued1 < 0 ? 'color:#F22233;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued1 || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Estoque Final -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Saldo Pedidos de Compra (D-1)
                      <v-icon v-bind="attrs" v-on="on" small color="primary"
                        class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Custo Última Compra</span>
                  </v-tooltip>
                </td>
                <template v-for="l in lojas">
                  <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharPedidos(l, objNomeLojas[l])" class="botao_detalhe2 px-2">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                          <span>R$</span><span>{{ totalizadores.pedidos[0].lojas[objLojas[l]] || 0 |
                            formataDinheiroTabela }} </span>
                        </div>
                      </v-btn>
                    </v-hover>
                  </td>
                </template>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>R$</span><span>{{ totalizadores.pedidos[0].totalpedidos || 0 | formataDinheiroTabela }}
                    </span>
                  </div>
                </td>
              </tr> <!--  Saldo Pedidos -->
              <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <template>
                    Mercadorias Recebidas (Realtime)
                  </template>
                </td>
                <template v-for="l in lojas">
                  <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharMercadoriasComprador(l, objNomeLojas[l])"
                        class="botao_detalhe2 px-2">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                          <span>R$</span><span>{{ totalizadores.mercadoriasRecebidas.principal[0].lojas[objLojas[l]] ||
                            0 |
                            formataDinheiroTabela }} </span>
                        </div>
                      </v-btn>
                    </v-hover>
                  </td>
                </template>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharMercadoriasComprador(null, 'Total do Período')"
                      class="botao_detalhe2 px-2">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <span>R$</span><span>{{ totalizadores.mercadoriasRecebidas.principal[0].totalmercadoriareceber
                          || 0 |
                          formataDinheiroTabela }} </span>
                      </div>
                    </v-btn>
                  </v-hover>
                </td>
              </tr> <!--  Mercadorias Recebidas -->
              <tr v-if="totalizadores.transferencias.principal[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <template>
                    Transferências de Pedidos de Compra
                  </template>
                </td>
                <template v-for="l in lojas">
                  <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span>R$</span><span>{{ totalizadores.transferencias.principal[0].lojas[objLojas[l]] || 0 |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </template>
                <td class="px-2 text-center barra-total-left" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <span>R$</span><span>{{ totalizadores.transferencias.principal[0].total || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Transferências de Pedidos de Compra -->
              <tr v-if="totalizadores.itensAtivos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.itensAtivos[0].lojas['loja' + n] || 0 }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.itensAtivos[0].totalitensativos || 0 }}
                </td>
              </tr> <!--  Total de Sku's em Estoque -->
              <tr v-if="totalizadores.itensEncalhados[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.itensEncalhados[0].lojas['loja' + n] || 0 }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.itensEncalhados[0].totalitensencalhados || 0 }}
                </td>
              </tr> <!--  Sku's Encalhados - Em desenvolvimento ...-->
              <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais" class="barra-total-top-final">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
              <tr v-if="totalizadores.totalRupturas && !carregandoTotais" class="barra-total-top-final" style="position: relative;">
                <td class="text-left py-1 coluna-botao" nowrap>
                  <v-menu transition="slide-x-transition" height="500px" :close-on-content-click="false">
                    <!-- Botão visível que abre o menu -->
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          :loading="carregando"
                          v-bind="attrs"
                          v-on="on"
                          tile
                          height="290px"
                          x-small
                          color="primary"
                          class="fixed-botao-menu"
                      >
                        <v-icon color="white">mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <!-- Conteúdo do menu -->
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-simple-table>
                          <thead style="background-color: #CFD8DC">
                            <tr>
                              <th class="white--text font-weight-bold text-center">TOTAL</th>
                              <th class="white--text font-weight-bold text-center">GERAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>Ruptura A</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>
                                {{ totalizadores.totalRupturas.A }}
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>Curva A</td>
                              <td class="px-0 text-center" nowrap>
                                <v-hover v-slot="{ hover }">
                                  <v-btn text width="100%" class="botao_detalhe" @click="detalharCurvaGeral('A')">
                                    <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                                    {{ totalizadores.totalCurvas.A  }}
                                  </v-btn>
                                </v-hover>
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>% Ruptura Curva A</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>
                                {{ totalizadores.percRupturas.A }}%
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>Ruptura B</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>{{ totalizadores.totalRupturas.B }}</td>
                            </tr>
                            <tr class="border-line">
                              <td class="py-0 font-weight-bold" nowrap>Curva B</td>
                              <td class="px-0 text-center" nowrap>
                                <v-hover v-slot="{ hover }">
                                  <v-btn text width="100%" class="botao_detalhe" @click="detalharCurvaGeral('B')">
                                    <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                                    {{ totalizadores.totalCurvas.B  }}
                                  </v-btn>
                                </v-hover>
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>% Ruptura Curva B</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>
                                {{ totalizadores.percRupturas.B }}%
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>Ruptura C</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>{{ totalizadores.totalRupturas.C }}</td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-1 font-weight-bold" nowrap>Curva C</td>
                              <td class="px-0 text-center" nowrap>
                                <v-hover v-slot="{ hover }">
                                  <v-btn text width="100%" class="botao_detalhe" @click="detalharCurvaGeral('C')">
                                    <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                                    {{ totalizadores.totalCurvas.C  }}
                                  </v-btn>
                                </v-hover>
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>% Ruptura Curva C</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>
                                {{ totalizadores.percRupturas.C }}%
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>Total Rupturas</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>{{ totalizadores.totalRupturas.TOTAL }}</td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-1 font-weight-bold" nowrap>Total Curvas</td>
                              <td class="px-0 text-center" nowrap>
                                <v-hover v-slot="{ hover }">
                                  <v-btn text width="100%" class="botao_detalhe" @click="detalharCurvaGeral('T')">
                                    <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                                    {{ totalizadores.totalCurvas.TOTAL  }}
                                  </v-btn>
                                </v-hover>
                              </td>
                            </tr>
                            <tr class="border-line">
                              <td class="text-left py-0 font-weight-bold" nowrap>% de Ruptura Total</td>
                              <td class="px-0 text-center font-weight-bold" nowrap>
                                {{ totalizadores.percRupturas.TOTAL }}%
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </td>
                <td colspan="100%"></td>
              </tr> <!-- Botão de total de rupturas e curvas ...  -->
              <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">Sku's em Ruptura da Curva A</td>
                <template v-for="l in lojas">
                  <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRuptura(l, 'RA', objNomeLojas[l])" class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadores.rupturaA[0].valordesconto[objLojas[l]] || 0 }}
                      </v-btn>
                    </v-hover>
                  </td>
                </template>
                <td class="px-0 text-center barra-total-left" nowrap>
                  {{ totalizadores.rupturaA[0].totalrupturaa || 0 }}
                </td>
              </tr> <!--  Sku's em Ruptura da Curva A -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">Total de Sku's da Curva A</td>
                <template v-for="l in lojas">
                  <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRuptura(l, 'CA', objNomeLojas[l])" class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadores.curva[0].itenscurvaa[objLojas[l]].toFixed(0) || 0 }}
                      </v-btn>
                    </v-hover>
                  </td>
                </template>
                <td class="px-0 text-center barra-total-left" nowrap>
                  {{ totalizadores.curva[0].totalcurvaa.toFixed(0) || 0 }}
                </td>
              </tr> <!--  Total de Sku's da Curva A -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">% Ruptura da Curva A</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curva[0].perccurvaa['loja' + n].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.curva[0].totalperca.toFixed(2) || 0 }}%
                </td>
              </tr> <!--  % Ruptura da Curva A -->
              <tr v-if="totalizadores.rupturaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">Sku's em Ruptura da Curva B/C</td>
                <template v-for="l in lojas">
                  <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRuptura(l, 'RB', objNomeLojas[l])" class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadores.rupturaBC[0].lojas[objLojas[l]] || 0 }}
                      </v-btn>
                    </v-hover>
                  </td>
                </template>
                <td class="px-0 text-center barra-total-left" nowrap>
                  {{ totalizadores.rupturaBC[0].totalrupturabc || 0 }}
                </td>
              </tr> <!--  Sku's em Ruptura da Curva B/C -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">Total de Sku's da Curva B/C</td>
                <template v-for="l in lojas">
                  <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRuptura(l, 'CB', objNomeLojas[l])" class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadores.curva[0].itenscurvabc[objLojas[l]].toFixed(0) || 0 }}
                      </v-btn>
                    </v-hover>
                  </td>
                </template>
                <td class="px-0 text-center barra-total-left" nowrap>
                  {{ totalizadores.curva[0].totalbc.toFixed(0) || 0 }}
                </td>
              </tr> <!--  Total de Sku's da Curva B/C-->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">% Ruptura da Curva B/C</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curva[0].perccurvabc['loja' + n].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.curva[0].totalpercbc.toFixed(2) || 0 }}%
                </td>
              </tr> <!--  % Ruptura da Curva B/C -->
              <tr v-if="totalizadores.rupturaGeral[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">Sku's em Ruptura da Curva (A,B,C)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.rupturaGeral[0].lojas['loja' + n] || 0 }}
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.rupturaGeral[0].totalruptura || 0 }}
                </td>
              </tr> <!--  Sku's em Ruptura da Curva (A,B,C) -->
              <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">% de Ruptura Total (A,B,C)</td>
                <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                  {{ totalizadores.curvaGeral[0].lojas['loja' + n].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center barra-total-left" nowrap>
                  {{ totalizadores.curvaGeral[0].totalcurva.toFixed(2) || 0 }}%
                </td>
              </tr> <!--  % de Ruptura Total (A,B,C) -->
              <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap style="font-size: 12px">Total de Sku's da Curva A/B/C</td>
                <template v-for="l in lojas">
                  <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                    {{ (totalizadores.curva[0].itenscurvabc[objLojas[l]] + totalizadores.curva[0].itenscurvaa[objLojas[l]]).toFixed(0) || 0 }}
                  </td>
                </template>
                <td class="px-0 text-center barra-total-left" nowrap>
                  {{ (totalizadores.curva[0].totalbc + totalizadores.curva[0].totalcurvaa).toFixed(0) || 0 }}
                </td>
              </tr> <!--  Total de Sku's da Curva A + B/C-->
              <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2" class="barra-total-top">
                <td colspan="100%"></td>
              </tr> <!-- ......................  -->
            </tfoot>
          </v-simple-table>


          <!-- TABLE DE TOTALIZADORES DE SEÇÃO -->
          <v-simple-table light v-else-if="vendasSecao[0] && !carregando" fixed-header height="50vh">
            <thead ref="thead">
              <tr class="border-line-head">
                <th class="text-center fixed-column-header white--text" nowrap>
                  <v-row class="pb-0" no-gutters>
                    <v-col cols="3" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                      <div>
                        <v-tooltip bottom color="primary" class="text-no-wrap">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :loading="carregandoTotais" v-bind="attrs" v-on="on" @click="dialog = true"
                              elevation="0" class="primary mt-n1 ml-n4 pa-0" x-small style="border-radius: 0px;"
                              height="39px" dark><v-icon class="mt-1">mdi-arrow-expand-all</v-icon></v-btn>
                          </template>
                          <span>Visualizar em tela cheia</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="9" class="pa-0" style="display: flex; justify-content: center; align-items: center;">
                      <div class="mr-11">
                        DATA
                      </div>
                    </v-col>
                  </v-row>
                </th>
                <th class="text-center white--text" nowrap v-for="st in codSecoesArray" :key="st">{{ tituloSecoes[st] }}
                </th>
                <th class="text-center white--text barra-total-left" nowrap>TOTAL</th>
              </tr>
            </thead>
            <tbody v-if="vendasSecao[0]">
              <tr v-for="(u, i) in vendasSecao" :key="i" class="border-line">
                <td class="px-2 text-center fixed-column" nowrap> {{ u.dtmovimento || '' }}</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="u.secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.secao[s] || 0 | formataDinheiroTabela
                      }}
                    </span>
                  </div>
                </td>
                <td class="px-2 text-right barra-total-left column-totais" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ u.totaldia || 0 | formataDinheiroTabela }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot v-if="totalizadoresSecao.vendas[0]">
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <!--
              <tr v-if="totalizadoresSecao.variacoes.vendaLiquida[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Liquida</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.variacoes.vendaLiquida[0].secao[s] || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.variacoes.vendaLiquida[0].totalvendaliquida || 0 | formataDinheiroTabela }}
                    </span>
                  </div>
                </td>
              </tr> Total Venda Liquida -->
              <tr v-if="totalizadoresSecao.vendas[0]" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].secao[s] || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.vendas[0].totalsecaoempresa < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].totalsecaoempresa ||
                      0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Venda Bruta -->
              <tr v-if="totalizadoresSecao.perparticSecao[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Seção</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perparticSecao[0].secao[s].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perparticSecao[0].totalsecaoempresa.toFixed(2) || 0 }}%
                </td>
              </tr><!-- % Partic. Seção -->

              <!--
              <tr v-if="totalizadoresSecao.perparticRegiao[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perparticRegiao[0].secao[s].toFixed(2) || 0 }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perparticRegiao[0].totalpartcregionalgeral.toFixed(2) || 0 }}%
                </td>
              </tr> % Partic. Regional -->

              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <!--
              <tr v-if="totalizadoresSecao.variacoes.vendaAnoAnt[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Ano Ant.</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.variacoes.vendaAnoAnt[0].secao[s] || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.variacoes.vendaAnoAnt[0].totalvendalastyear || 0 | formataDinheiroTabela }}
                    </span>
                  </div>
                </td>
              </tr> Venda Liq. Ano Ant. -->
              <tr v-if="totalizadoresSecao.variacoes.variacaoNominal[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação A.A.</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label
                    :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].secao[s])">
                    {{ totalizadoresSecao.variacoes.variacaoNominal[0].secao[s].toFixed(2) || 0 }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label
                    :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal)">
                    {{ totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal.toFixed(2) || 0 }}%
                  </v-chip>
                </td>
              </tr><!-- % Variação A.A. -->
              <!--
              <tr v-if="totalizadoresSecao.variacoes.vendaMesAnt[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.variacoes.vendaMesAnt[0].secao[s] || 0 |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.variacoes.vendaMesAnt[0].totalvendalastmonth | formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> Venda Liq. Mês Ant. -->
              <tr v-if="totalizadoresSecao.variacoes.variacao[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].secao[s])">
                    {{ totalizadoresSecao.variacoes.variacao[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].variacao)">
                    {{ totalizadoresSecao.variacoes.variacao[0].variacao.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- % Variação Mês Ant. -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <!--
              <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Vendas de Obsoletos (R$)
                      <v-icon v-bind="attrs" v-on="on" small color="red" class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Apoio a decisão</span>
                  </v-tooltip>
                </td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendasObsoletos[0].secoes[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.vendasObsoletos[0].total_venda_obsoleto
                      | formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr> Vendas de Obsoletos (R$) -->
              <!--
              <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.vendasObsoletos[0].margens[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) }}%
                </td>
              </tr> Margem de Obsoletos (%) -->
              <!--
              <tr v-if="totalizadoresSecao.estoqueObsoletos[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.estoqueObsoletos[0].secoes[s] | formataDinheiro }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.estoqueObsoletos[0].total_estoque | formataDinheiro }}
                </td>
              </tr>   Estoque de Obsoletos -->
              <!--
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr> -->
              <tr v-if="totalizadoresSecao.devolucao[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total Devoluções (R$)</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].secao[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].totaldevolucao |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Devoluções (R$) -->
              <tr v-if="totalizadoresSecao.perDevolucao[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perDevolucao[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perDevolucao[0].totaldevolucao.toFixed(2) }}%
                </td>
              </tr><!--  Total Devoluções (%) -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.descontos.desconto[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.descontos.desconto[0].secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.descontos.desconto[0].secao[s]
                      |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.descontos.desconto[0].totaldesconto |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr><!-- Total Desconto -->
              <tr v-if="totalizadoresSecao.descontos.perdesconto[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.descontos.perdesconto[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.descontos.perdesconto[0].totaldesconto.toFixed(2) }}%
                </td>
              </tr><!-- Total de Descontos (%) -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.margemMeta[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.margemMeta[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.margemMeta[0].totalmargemmetafilial.toFixed(2) }}%
                </td>
              </tr> <!--  Margem Meta -->
              <tr v-if="totalizadoresSecao.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Realizada Acum.</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].secao[s])">
                    {{ totalizadoresSecao.margem[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].totalmargemgeral)">
                    {{ totalizadoresSecao.margem[0].totalmargemgeral.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- Margem Realizada Acum. -->
              <tr v-if="totalizadoresSecao.margemAnoAnt[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].secao[s])">
                    {{ totalizadoresSecao.margemAnoAnt[0].secao[s].toFixed(2) }}%
                  </v-chip>
                </td>
                <td class="px-2 text-center" nowrap>
                  <v-chip small label :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral)">
                    {{ totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral.toFixed(2) }}%
                  </v-chip>
                </td>
              </tr><!-- Margem Ano Anterior -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Entrada Ajuste de Estoque</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Entrada Ajuste de Estoque -->
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saída Ajuste de Estoque</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Saída Ajuste de Estoque -->
              <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Saldo de Ajustes</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Saldo de Ajustes -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.estoqueAnoAnt[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.estoqueAnoAnt[0].secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueAnoAnt[0].secao[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!--  Estoque Ano Anterior -->
              <tr v-if="totalizadoresSecao.diasEstoqueFuturo[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.diasEstoqueFuturo[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.diasEstoqueFuturo[0].totaldiasestoquefuturofilial }}
                </td>
              </tr><!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
              <tr v-if="totalizadoresSecao.diasEstoqueReal[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.diasEstoqueReal[0].secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.diasEstoqueReal[0].secao[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial | formataDinheiroTabela }}
                    </span>
                  </div>
                </td>
              </tr><!--  Dias de Estoque - Real - Em desenvolvimento ...-->
              <tr v-if="totalizadoresSecao.estoqueInicial[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.estoqueInicial[0].secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueInicial[0].secao[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.estoqueInicial[0].totalestoqueinicial < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.estoqueInicial[0].totalestoqueinicial |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Estoque Inicial -->
              <!--
              <tr v-if="totalizadoresSecao.movimentacoes[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Movimentações</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.movimentacoes[0].secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.movimentacoes[0].secao[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.movimentacoes[0].totalmovimentacoes < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.movimentacoes[0].totalmovimentacoes |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr> Movimentacoes -->
              <tr v-if="totalizadoresSecao.estoqueFinal[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.estoqueFinal[0].secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueFinal[0].secao[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.estoqueFinal[0].totalestoquefinal < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.estoqueFinal[0].totalestoquefinal |
                      formataDinheiroTabela }}</span>
                  </div>
                </td>
              </tr><!-- Estoque Final -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      Saldo Pedidos de Compra (D-1)
                      <v-icon v-bind="attrs" v-on="on" small color="primary"
                        class="mt-n1">mdi-information-outline</v-icon>
                    </template>
                    <span>Custo Última Compra</span>
                  </v-tooltip>
                </td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro" :style="totalizadoresSecao.pedidos[0].secao[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].secao[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.pedidos[0].totalpedido < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].totalpedido |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!--  Saldo Pedidos -->
              <tr v-if="totalizadoresSecao.mercadoriasRecebidas.secoes[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>
                  <template>
                    Mercadorias Recebidas (Realtime)
                  </template>
                </td>
                <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                  <div class="espaco-dinheiro"
                    :style="totalizadoresSecao.mercadoriasRecebidas.secoes[0].secoes[s] < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.mercadoriasRecebidas.secoes[0].secoes[s] |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
                <td class="px-2 text-right" nowrap>
                  <div style="display: flex; justify-content: space-between; align-items: center;"
                    :style="totalizadoresSecao.mercadoriasRecebidas.secoes[0].totalmrsecao < 0 ? 'color:red;' : ''">
                    <span style="margin-right: 5px;">R$</span><span>{{
                      totalizadoresSecao.mercadoriasRecebidas.secoes[0].totalmrsecao |
                      formataDinheiroTabela }} </span>
                  </div>
                </td>
              </tr> <!-- Mercadorias Recebidas -->
              <tr v-if="totalizadoresSecao.transferencias.secoes[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Transferências de Pedido de Compras</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.transferencias.secoes[0].secoes[s] | formataDinheiro }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.transferencias.secoes[0].total | formataDinheiro }}
                </td>
              </tr> <!--   Transferência de pedido de compras -->
              <tr v-if="totalizadoresSecao.itensAtivos[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.itensAtivos[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.itensAtivos[0].totalitensativosfilial }}
                </td>
              </tr> <!--  Total de Sku's em Estoque -->
              <tr v-if="totalizadoresSecao.itensEncalhados[0] && !carregandoTotais"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.itensEncalhados[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.itensEncalhados[0].totalitensencalhadosfilial.toFixed(2) }}%
                </td>
              </tr> <!--  Sku's Encalhados -->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
              <tr v-if="totalizadoresSecao.rupturaA[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva A</td>
                <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'RA', '')"
                      class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaA[0].secao[s] }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaA[0].totalrupturaafilial }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva A-->
              <tr v-if="totalizadoresSecao.curvaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A</td>
                <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'CA', '')"
                      class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaA[0].secao[s].toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.curvaA[0].curvaa.toFixed(0) }}
                </td>
              </tr><!-- Total de Sku's da Curva A-->
              <tr v-if="totalizadoresSecao.perCurvaA[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva A</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perCurvaA[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.perCurvaA[0].TotalPerccurvaa.toFixed(2) }}%
                </td>
              </tr><!-- % Ruptura da Curva A-->
              <tr v-if="totalizadoresSecao.rupturaBC[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva B/C</td>
                <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'RB', '')"
                      class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.rupturaBC[0].secao[s] }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaBC[0].totalrupturaBcfilial }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva B/C-->
              <tr v-if="totalizadoresSecao.curvaBC[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva B/C</td>
                <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                  <v-hover v-slot="{ hover }">
                    <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'CB', '')"
                      class="botao_detalhe">
                      <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                      {{ totalizadoresSecao.curvaBC[0].secao[s].toFixed(0) }}
                    </v-btn>
                  </v-hover>
                </td>
                <td class="px-0 text-center" nowrap>
                  {{ totalizadoresSecao.curvaBC[0].Totalcurvabc.toFixed(0) }}
                </td>
              </tr><!-- Total de Sku's da Curva B/C-->
              <tr v-if="totalizadoresSecao.perCurvaBC[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva B/C</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.perCurvaBC[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.perCurvaBC[0].Totalcurvabc.toFixed(2) }}%
                </td>
              </tr><!-- % Ruptura da Curva B/C-->
              <tr v-if="totalizadoresSecao.rupturaGeral[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva (A,B,C)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.rupturaGeral[0].secao[s] }}
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.rupturaGeral[0].totalruptura }}
                </td>
              </tr><!-- Sku's em Ruptura da Curva (A,B,C)-->
              <tr v-if="totalizadoresSecao.curvaGeral[0] && !carregandoTotais2"
                class="border-line-foot font-weight-medium">
                <td class="text-left py-1 fixed-column" nowrap>% de Ruptura Total (A,B,C)</td>
                <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                  {{ totalizadoresSecao.curvaGeral[0].secao[s].toFixed(2) }}%
                </td>
                <td class="px-2 text-center" nowrap>
                  {{ totalizadoresSecao.curvaGeral[0].totalcurvafilialloja.toFixed(2) }}%
                </td>
              </tr><!-- % de Ruptura Total (A,B,C)-->
              <tr v-if="totalizadoresSecao.curvaBC[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A/B/C</td>
                  <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                        {{ (totalizadoresSecao.curvaBC[0].secao[s] + totalizadoresSecao.curvaA[0].secao[s]).toFixed(0) }}
                  </td>
                  <td class="px-0 text-center" nowrap>
                    {{ (totalizadoresSecao.curvaBC[0].Totalcurvabc + totalizadoresSecao.curvaA[0].curvaa).toFixed(0) }}
                  </td>
                </tr><!-- Total de Sku's da Curva A + B/C-->
              <tr class="barra-total-top">
                <td colspan="100%"></td>
              </tr>
            </tfoot>
          </v-simple-table>
          <v-alert class="error mb-0" v-else-if="!carregando">Nenhum registro encontrado.</v-alert>

        </v-card-text>
        <v-row class="px-7 pb-1" v-if="carregandoTotais2">
          <v-progress-circular class="mt-1 mr-1" indeterminate color="white" :size="12"></v-progress-circular>
          <span class="text-caption font-italic">Carregando totais...</span>
        </v-row>
      </v-card>
    </v-col>

    <!-- MODO TELA CHEIA -->
    <v-dialog v-model="dialog" fullscreen transition="dialog-transition">
      <v-card>
        <v-sheet class="overflow-hidden" style="position: relative;">
          <v-navigation-drawer class="primary" v-model="sheet" absolute temporary dark>
            <v-col cols="12" class="px-4">
              <v-row class="pa-3">
                <v-toolbar-title>
                  <router-link to="/">
                    <v-img max-height="40" max-width="155" src="../../assets/icons/Gestor_Logo_.svg" />
                  </router-link>
                </v-toolbar-title>
              </v-row>
            </v-col>
            <v-divider />
            <v-col cols="12" class="px-3 pt-3 pb-2">
              <v-select label="Período" filled dense :items="periodos" item-text="data" item-value="data"
                :disabled="carregando" v-model="busca.periodo.data" hide-details></v-select>
            </v-col>
            <v-col cols="12" class="px-3 py-2">
              <v-select label="Filial" filled dense :items="filiaisFiltro" item-text="filial" item-value="idfilial"
                :disabled="carregando" v-model="busca.idempresa" hide-details clearable></v-select>
            </v-col>
            <v-col cols="12" class="px-3 py-2" v-show="(!busca.idempresa)">
              <v-select label="Regional" filled dense :items="regionais" item-text="regional" item-value="idregional"
                :disabled="carregando" v-model="busca.idregional" hide-details clearable></v-select>
            </v-col>
            <v-col cols="12" class="px-3 py-2" v-show="(!busca.idempresa)">
              <v-select label="Seção" filled dense :items="secoes" item-text="descr" item-value="idsecao"
                :disabled="carregando" v-model="busca.idsecao" hide-details clearable></v-select>
            </v-col>
            <v-col class="px-3 pt-2 pb-1">
              <v-btn width="100%" light @click="listarVendas(), sheet = !sheet">Filtrar</v-btn>
            </v-col>
            <v-col class="px-3 pt-2">
              <v-btn width="100%" light @click="sheet = !sheet"><span style="color: red">Cancelar</span></v-btn>
            </v-col>
          </v-navigation-drawer>
          <v-card-text class="pa-0">
            <v-skeleton-loader light v-if="carregando || carregandoTotais" type="table-tbody" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais" type="table-tbody" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais" type="table-tbody" />
            <v-simple-table light v-else-if="vendasLojas[0] && !carregando" fixed-header height="100vh">
              <thead ref="thead">
                <tr class="border-line-head">
                  <th class="fixed-column-header white--text text-center" nowrap>
                    <v-row class="pb-0" no-gutters>
                      <v-col cols="1" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                        <v-row class="pb-0" no-gutters>
                          <div>
                            <v-tooltip bottom color="error" class="text-no-wrap">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="dialog = false" elevation="0"
                                  class="red ml-n4 pa-0" x-small style="border-radius: 2px;" height="28px" dark><v-icon
                                    size="20px" class="mt-1">mdi-arrow-left-bold</v-icon></v-btn>
                              </template>
                              <span>Fechar tela cheia</span>
                            </v-tooltip>
                          </div>
                          <div>
                            <v-tooltip bottom color="primary" class="text-no-wrap">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="sheet = !sheet" elevation="0"
                                  class="primary ml-n4 pa-0" x-small style="border-radius: 2px;" height="30px"
                                  dark><v-icon small class="mt-1">mdi-filter-menu</v-icon></v-btn>
                              </template>
                              <span>Menu Filtros</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                      </v-col>
                      <v-col cols="11" class="pa-0"
                        style="display: flex; justify-content: center; align-items: center;">
                        <div class="mr-9 text-subtitle-2 font-weight-medium">
                          DATA
                        </div>
                      </v-col>
                    </v-row>
                  </th>
                  <template v-for="l in lojas">
                    <th class="text-center white--text text-subtitle-2 font-weight-medium" :key="l" nowrap
                      v-if="validarNumero(l)">{{ objNomeLojas[l] }}</th>
                  </template>
                  <th class="text-center white--text text-subtitle-2 font-weight-medium barra-total-left" nowrap>TOTAL
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(u, i) in vendasLojas" :key="i" class="border-line">
                  <td class="px-2 text-center fixed-column" nowrap> {{ u.data | formataData }} </td>
                  <template v-for="l in lojas">
                    <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                      <div style="display: flex; justify-content: space-between; align-items: center;"
                        :style="u.lojas[objLojas[l]] < 0 ? 'color:#F22233;' : ''">
                        <span style="margin-right: 5px;">R$</span><span>{{ u.lojas[objLojas[l]] | formataDinheiroTabela
                          }} </span>
                      </div>
                    </td>
                  </template>
                  <td class="px-2 text-right barra-total-left column-totais" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="u.total_venda_bruta_dia < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ u.total_venda_bruta_dia | formataDinheiroTabela
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="vendasLojas[0] && vendasLojas[0]">
                <tr v-if="totalizadores.vendas.totaloja[0]" class="barra-total-top">
                  <td colspan="100%" style="height: 3px !important"></td>
                </tr>
                <!--
                <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total Venda Líquida</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.variacoes[0].vendaliquida['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.variacoes[0].vendaliquida['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.variacoes[0].totalvendaliquida < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.variacoes[0].totalvendaliquida |
                        formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr>   Total Venda Líquida -->
                <tr v-if="totalizadores.vendas.totaloja[0]" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.vendas.totaloja[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.vendas.totaloja[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.vendas.totalvendaLiquida < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.vendas.totalvendaLiquida |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Total Venda Bruta -->
                <tr v-if="totalizadores.vendas.perclojas[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Partic. Geral</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                    :style="totalizadores.vendas.perclojas[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.vendas.perclojas[0].lojas['loja' + n].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap
                    :style="totalizadores.vendas.perclojas[0].totalparticipacaogeral < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.vendas.perclojas[0].totalparticipacaogeral.toFixed(2) }}%
                  </td>
                </tr> <!--  % Partic. Geral -->
                <!--
                <tr v-if="totalizadores.vendas.percpartregionais[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                    :style="totalizadores.vendas.percpartregionais[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.vendas.percpartregionais[0].lojas['loja' + n].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap
                    :style="totalizadores.vendas.percpartregionais[0].totalpartregional < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.vendas.percpartregionais[0].totalpartregional.toFixed(2) }}%
                  </td>
                </tr>   % Partic. Região -->
                <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <tr v-if="totalizadores.metasMes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Meta de Venda - Mês</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.metasMes[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.metasMes[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.metasMes[0].totalmeta < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasMes[0].totalmeta |
                        formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr> <!--  Meta de venda - Mes -->
                <tr v-if="totalizadores.metasDia[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Meta de Venda Acumulada</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.metasDia[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.metasDia[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.metasDia[0].totalmeta < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span> {{ totalizadores.metasDia[0].totalmetadia |
                        formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr> <!--  Meta de venda Acumulada -->
                <tr v-if="totalizadores.metasVariacao[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">% Variação de Meta</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].lojas['loja' + n])">
                      {{ totalizadores.metasVariacao[0].lojas['loja' + n].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :class="coresVarMeta(totalizadores.metasVariacao[0].TotalMetaVariacao)">
                      {{ totalizadores.metasVariacao[0].TotalMetaVariacao.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr> <!--  % Variação de Meta -->
                <!--
                <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap ref="compvariacao">Venda Liq. Ano Ant.</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div
                      :style="totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.variacoes[0].vendabrutaanoanterior['loja' + n] | formataDinheiroTabela
                        }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.variacoes[0].totalvendabrutaanoanterior < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span> {{
                        totalizadores.variacoes[0].totalvendabrutaanoanterior | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr>   Venda Liq. Ano Ant. -->
                <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Variação Nominal</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    <v-chip small label
                      :class="coresVarariacoes(totalizadores.variacoes[0].variacaonominalperc['loja' + n])">
                      {{ totalizadores.variacoes[0].variacaonominalperc['loja' + n].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :class="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacaonominal)">
                      {{ totalizadores.variacoes[0].totalpercvariacaonominal.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr> <!--  % Var. Nominal -->
                <!--
                <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div
                      :style="totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.variacoes[0].vendabrutamesanterior['loja' + n] | formataDinheiroTabela
                        }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.variacoes[0].totalvendabrutamesanterior < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>
                        {{ totalizadores.variacoes[0].totalvendabrutamesanterior | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr>   Venda Liq. Mês Ant. -->
                <tr v-if="totalizadores.variacoes[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    <v-chip small label
                      :class="coresVarariacoes(totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n])">
                      {{ totalizadores.variacoes[0].vendabrutavariacaomes['loja' + n].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :color="coresVarariacoes(totalizadores.variacoes[0].totalpercvariacao)">
                      {{ totalizadores.variacoes[0].totalpercvariacao.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr> <!--  % Var. Mês Ant. -->
                <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <!--
                <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        Vendas de Obsoletos (R$)
                        <v-icon v-bind="attrs" v-on="on" small color="red"
                          class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <span>Apoio a decisão</span>
                    </v-tooltip>
                  </td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.vendasObsoletos[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.vendasObsoletos[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadores.vendasObsoletos[0].total_venda_obsoleto | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr>   Vendas de Obsoletos (R$) -->
                <!--
                <tr v-if="totalizadores.vendasObsoletos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margens['loja' + n])">
                      {{ totalizadores.vendasObsoletos[0].margens['loja' + n].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :color="coresMargem(totalizadores.vendasObsoletos[0].margem_venda_obsoleto)">
                      {{ totalizadores.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr>   Margem Obsoletos (%) -->
                <!--
                <tr v-if="totalizadores.estoqueObsoletos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>
                  <template v-for="l in lojas">
                    <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                      <v-hover v-slot="{ hover }">
                        <v-btn text width="100%" @click="detalharObsoletos(l, objNomeLojas[l])"
                          class="botao_detalhe2 px-2">
                          <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                          <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span>R$</span><span>{{ totalizadores.estoqueObsoletos[0].lojas['loja' + l] || 0 |
                              formataDinheiroTabela }} </span>
                          </div>
                        </v-btn>
                      </v-hover>
                    </td>
                  </template>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.estoqueObsoletos[0].total_estoque | formataDinheiro }}
                  </td>
                </tr>   Estoque de Obsoletos - Em desenvolvimento ...-->

                <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr><!--  ......................  -->
                <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap ref="compdevolucao">Total Devoluções (R$)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.devolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.devolucao[0].devolucao['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.devolucao[0].totaldevolucao |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Total Devoluções (R$) -->
                <tr v-if="totalizadores.perDevolucao[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                    :style="totalizadores.perDevolucao[0].devolucao['loja' + n] < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.perDevolucao[0].devolucao['loja' + n].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.perDevolucao[0].totaldevolucao.toFixed(2) }}%
                  </td>
                </tr> <!--  Total Devoluções (%) -->
                <tr v-if="totalizadores.devolucao[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <!--
                <tr v-if="totalizadores.metasDesconto[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Metas de Descontos (R$)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div
                      :style="totalizadores.metasDesconto[0].metadescontoreal['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.metasDesconto[0].metadescontoreal['loja' + n] | formataDinheiroTabela
                        }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.metasDesconto[0].metatotalreal < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.metasDesconto[0].metatotalreal |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr>   Metas de Descontos (R$) -->
                <!--
                <tr v-if="totalizadores.metasPerDesconto[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Metas de Descontos (%)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap
                    :style="totalizadores.metasPerDesconto[0].metadesconto['loja' + n] < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.metasPerDesconto[0].metadesconto['loja' + n].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.metasPerDesconto[0].metatotal.toFixed(2) }}%
                  </td>
                </tr>  Metas de Descontos (%)  -->
                <tr v-if="totalizadores.desconto[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.desconto[0].valordesconto['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.desconto[0].valordesconto['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.totalvendabrutageral < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.desconto[0].totaldesconto |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Total Desconto -->
                <tr v-if="totalizadores.desconto[0] && totalizadores.metasPerDesconto[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresPerDesconto(['loja' + n])">
                      {{ totalizadores.desconto[0].descontoconcedido['loja' + n].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label
                      :class="totalizadores.desconto[0].totaldescontoconcedido > totalizadores.metasPerDesconto[0].metatotal ? 'red white--text' : 'blue darken-2 white--text'">
                      {{ totalizadores.desconto[0].totaldescontoconcedido.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr> <!--  % Desconto -->
                <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <tr v-if="totalizadores.margemMeta != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                  <td v-for="(chave) in Object.keys(totalizadores.margemMeta).filter(chave => chave !== 'TOTAL' && chave !== '42')" :key="chave" class="px-2 text-center" nowrap
                      :style="totalizadores.margemMeta[chave] < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.margemMeta[chave] || 0 }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap
                      :style="totalizadores.margemMeta.TOTAL < 0 ? 'color:#F22233;' : ''">
                    {{ totalizadores.margemMeta.TOTAL || 0 }}%
                  </td>
                </tr> <!--  Margem Meta ...-->
                <tr v-if="totalizadores.margemAcumulada != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        Margem Realizada Fat.
                        <v-icon v-bind="attrs" v-on="on" small color="red darken-3"
                                class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <span>Margem baseada no Faturamento</span>
                    </v-tooltip>
                  </td>
                  <td v-for="(chave) in Object.keys(totalizadores.margemAcumulada).filter(chave => chave !== 'TOTAL' && chave !== '42' )" :key="chave" class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margemAcumulada[chave])">
                      {{ totalizadores.margemAcumulada[chave] || 0 }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margemAcumulada.TOTAL)">
                      {{ totalizadores.margemAcumulada.TOTAL || 0 }}%
                    </v-chip>
                  </td>
                </tr> <!--  Margem Realizada Fat. -->
                <tr v-if="totalizadores.margemVenda != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        Margem Realizada Apoio
                        <v-icon v-bind="attrs" v-on="on" small color="red darken-3"
                                class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <span>Margem sobre a Venda</span>
                    </v-tooltip>
                  </td>
                  <td v-for="(chave) in Object.keys(totalizadores.margemVenda).filter(chave => chave !== 'TOTAL' && chave !== '42' )" :key="chave" class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margemVenda[chave])">
                      {{ totalizadores.margemVenda[chave] || 0 }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margemVenda.TOTAL)">
                      {{ totalizadores.margemVenda.TOTAL || 0 }}%
                    </v-chip>
                  </td>
                </tr> <!--  Margem Realizada sobre a venda -->
                <tr v-if="totalizadores.margem[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margem[0].margemanopassado['loja' + n])">
                      {{ totalizadores.margem[0].margemanopassado['loja' + n].toFixed(2) || 0 }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margem[0].totalmargemlastyear)">
                      {{ totalizadores.margem[0].totalmargemlastyear.toFixed(2) || 0 }}%
                    </v-chip>
                  </td>
                </tr> <!--  Margem Ano Anterior -->
                <tr v-if="totalizadores.margemFatAnoAnt != null && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Margem Faturamento Ano Anterior</td>
                  <td v-for="(chave) in Object.keys(totalizadores.margemFatAnoAnt).filter(chave => chave !== 'TOTAL' && chave !== '42' )" :key="chave" class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margemFatAnoAnt[chave])">
                      {{ totalizadores.margemFatAnoAnt[chave] || 0 }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-chip small label :class="coresMargem(totalizadores.margemFatAnoAnt.TOTAL)">
                      {{ totalizadores.margemFatAnoAnt.TOTAL || 0 }}%
                    </v-chip>
                  </td>
                </tr> <!--  Margem Ano Anterior -->
                <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Entradas Avulsa</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.entradasSaidasAvulsa[0].lojasent['loja' + n] | formataDinheiroTabela
                        }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.entradasSaidasAvulsa[0].totalentrada < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadores.entradasSaidasAvulsa[0].totalentrada
                        | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Entrada Avulsa -->
                <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Saidas Avulsa</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div
                      :style="totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.entradasSaidasAvulsa[0].lojassaid['loja' + n] | formataDinheiroTabela
                        }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.entradasSaidasAvulsa[0].totalsaida < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadores.entradasSaidasAvulsa[0].totalsaida |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Saida Avulsa -->
                <tr v-if="totalizadores.entradasSaidasAvulsa[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Saldo</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.entradasSaidasAvulsa[0].saldo['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.entradasSaidasAvulsa[0].totalsaldo < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadores.entradasSaidasAvulsa[0].totalsaldo |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr>
                <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <tr v-if="totalizadores.estoqueAnoAnt[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.estoqueAnoAnt[0].estoqued1['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.estoqueAnoAnt[0].totalestoqued1 < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoqueAnoAnt[0].totalestoqued1 |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Estoque Ano Anterior -->
                <tr v-if="totalizadores.diasEstoqueFuturo[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    {{ totalizadores.diasEstoqueFuturo[0].lojas['loja' + n] }} dias
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.diasEstoqueFuturo[0].totaldiasestoquefuturo }} dias
                  </td>
                </tr> <!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
                <tr v-if="totalizadores.diasEstoqueReal[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.diasEstoqueReal[0].lojas['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.diasEstoqueReal[0].lojas['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.diasEstoqueReal[0].totaldiasestoquereal < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadores.diasEstoqueReal[0].totaldiasestoquereal | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr> <!--  Dias de Estoque - Real - Em desenvolvimento ...-->
                <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.estoque[0].estoqued2['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.estoque[0].estoqued2['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.estoque[0].totalestoqued2 < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued2 |
                        formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr> <!--  Estoque Inicial -->
                <!--
                <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Movimentação</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.estoque[0].Movimentacoes['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.estoque[0].Movimentacoes['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.estoque[0].totalMovimentacoes < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalMovimentacoes |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr>   Movimentação -->
                <tr v-if="totalizadores.estoque[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-right" nowrap>
                    <div :style="totalizadores.estoque[0].estoqued1['loja' + n] < 0 ? 'color:#F22233;' : ''"
                      class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span>
                      <span>{{ totalizadores.estoque[0].estoqued1['loja' + n] | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadores.estoque[0].totalestoqued1 < 0 ? 'color:#F22233;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadores.estoque[0].totalestoqued1 |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Estoque Final -->
                <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        Saldo Pedidos de Compra (D-1)
                        <v-icon v-bind="attrs" v-on="on" small color="primary"
                          class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <span>Custo Última Compra</span>
                    </v-tooltip>
                  </td>
                  <template v-for="l in lojas">
                    <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                      <v-hover v-slot="{ hover }">
                        <v-btn text width="100%" @click="detalharPedidos(l, objNomeLojas[l])"
                          class="botao_detalhe2 px-2">
                          <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                          <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span>R$</span><span>{{ totalizadores.pedidos[0].lojas[objLojas[l]] |
                              formataDinheiroTabela }} </span>
                          </div>
                        </v-btn>
                      </v-hover>
                    </td>
                  </template>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                      <span>R$</span><span>{{ totalizadores.pedidos[0].totalpedidos | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Saldo Pedidos -->
                <tr v-if="totalizadores.pedidos[0] && !carregandoTotais" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>
                    <template>
                      Mercadorias Recebidas (Realtime)
                    </template>
                  </td>
                  <template v-for="l in lojas">
                    <td class="px-2 text-right" :key="l" nowrap v-if="validarNumero(l)">
                      <v-hover v-slot="{ hover }">
                        <v-btn text width="100%" @click="detalharMercadoriasComprador(l, objNomeLojas[l])"
                          class="botao_detalhe2 px-2">
                          <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                          <div style="display: flex; justify-content: space-between; align-items: center;">
                            <span>R$</span><span>{{ totalizadores.mercadoriasRecebidas.principal[0].lojas[objLojas[l]] |
                              formataDinheiroTabela }} </span>
                          </div>
                        </v-btn>
                      </v-hover>
                    </td>
                  </template>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharMercadoriasComprador(null, 'Total do Período')"
                        class="botao_detalhe2 px-2">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                          <span>R$</span><span>{{ totalizadores.mercadoriasRecebidas.principal[0].totalmercadoriareceber
                            |
                            formataDinheiroTabela }} </span>
                        </div>
                      </v-btn>
                    </v-hover>
                  </td>
                </tr> <!--  Mercadorias Recebidas -->
                <tr v-if="totalizadores.itensAtivos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    {{ totalizadores.itensAtivos[0].lojas['loja' + n] }}
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.itensAtivos[0].totalitensativos }}
                  </td>
                </tr> <!--  Total de Sku's em Estoque -->
                <tr v-if="totalizadores.itensEncalhados[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    {{ totalizadores.itensEncalhados[0].lojas['loja' + n] }}
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.itensEncalhados[0].totalitensencalhados }}
                  </td>
                </tr> <!--  Sku's Encalhados - Em desenvolvimento ...-->
                <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> <!-- ......................  -->
                <tr v-if="totalizadores.rupturaA[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva A</td>
                  <template v-for="l in lojas">
                    <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                      <v-hover v-slot="{ hover }">
                        <v-btn text width="100%" @click="detalharRuptura(l, 'RA', objNomeLojas[l])"
                          class="botao_detalhe">
                          <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                          {{ totalizadores.rupturaA[0].valordesconto[objLojas[l]] }}
                        </v-btn>
                      </v-hover>
                    </td>
                  </template>
                  <td class="px-0 text-center barra-total-left" nowrap>
                    {{ totalizadores.rupturaA[0].totalrupturaa }}
                  </td>
                </tr> <!--  Sku's em Ruptura da Curva A -->
                <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A</td>
                  <template v-for="l in lojas">
                    <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                      <v-hover v-slot="{ hover }">
                        <v-btn text width="100%" @click="detalharRuptura(l, 'CA', objNomeLojas[l])"
                          class="botao_detalhe">
                          <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                          {{ totalizadores.curva[0].itenscurvaa[objLojas[l]].toFixed(0) }}
                        </v-btn>
                      </v-hover>
                    </td>
                  </template>
                  <td class="px-0 text-center barra-total-left" nowrap>
                    {{ totalizadores.curva[0].totalcurvaa.toFixed(0) }}
                  </td>
                </tr> <!--  Total de Sku's da Curva A -->
                <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva A</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    {{ totalizadores.curva[0].perccurvaa['loja' + n].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.curva[0].totalperca.toFixed(2) }}%
                  </td>
                </tr> <!--  % Ruptura da Curva A -->
                <tr v-if="totalizadores.rupturaBC[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva B/C</td>
                  <template v-for="l in lojas">
                    <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                      <v-hover v-slot="{ hover }">
                        <v-btn text width="100%" @click="detalharRuptura(l, 'RB', objNomeLojas[l])"
                          class="botao_detalhe">
                          <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                          {{ totalizadores.rupturaBC[0].lojas[objLojas[l]] }}
                        </v-btn>
                      </v-hover>
                    </td>
                  </template>
                  <td class="px-0 text-center barra-total-left" nowrap>
                    {{ totalizadores.rupturaBC[0].totalrupturabc }}
                  </td>
                </tr> <!--  Sku's em Ruptura da Curva B/C -->
                <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva B/C</td>
                  <template v-for="l in lojas">
                    <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                      <v-hover v-slot="{ hover }">
                        <v-btn text width="100%" @click="detalharRuptura(l, 'CB', objNomeLojas[l])"
                          class="botao_detalhe">
                          <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                          {{ totalizadores.curva[0].itenscurvabc[objLojas[l]].toFixed(0) }}
                        </v-btn>
                      </v-hover>
                    </td>
                  </template>
                  <td class="px-0 text-center barra-total-left" nowrap>
                    {{ totalizadores.curva[0].totalbc.toFixed(0) }}
                  </td>
                </tr> <!--  Total de Sku's da Curva B/C-->
                <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva B/C</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    {{ totalizadores.curva[0].perccurvabc['loja' + n].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.curva[0].totalpercbc.toFixed(2) }}%
                  </td>
                </tr> <!--  % Ruptura da Curva B/C -->
                <tr v-if="totalizadores.rupturaGeral[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva (A,B,C)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    {{ totalizadores.rupturaGeral[0].lojas['loja' + n] }}
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.rupturaGeral[0].totalruptura }}
                  </td>
                </tr> <!--  Sku's em Ruptura da Curva (A,B,C) -->
                <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% de Ruptura Total (A,B,C)</td>
                  <td v-for="n in lojasFiltradas" :key="n" class="px-2 text-center" nowrap>
                    {{ totalizadores.curvaGeral[0].lojas['loja' + n].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center barra-total-left" nowrap>
                    {{ totalizadores.curvaGeral[0].totalcurva.toFixed(2) }}%
                  </td>
                </tr> <!--  % de Ruptura Total (A,B,C) -->
                <tr v-if="totalizadores.curva[0] && !carregandoTotais2" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A/B/C</td>
                  <template v-for="l in lojas">
                    <td class="px-0 text-center" :key="l" nowrap v-if="validarNumero(l)">
                          {{ (totalizadores.curva[0].itenscurvabc[objLojas[l]] + totalizadores.curva[0].itenscurvaa[objLojas[l]]).toFixed(0) }}
                    </td>
                  </template>
                  <td class="px-0 text-center barra-total-left" nowrap>
                    {{ (totalizadores.curva[0].totalbc + totalizadores.curva[0].totalcurvaa).toFixed(0) }}
                  </td>
                </tr> <!--  Total de Sku's da Curva A + B/C-->
                <tr v-if="totalizadores.curvaGeral[0] && !carregandoTotais2" class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
              </tfoot>
            </v-simple-table>

            <!-- CAMPOS DE SECAO -->
            <v-simple-table light v-else-if="vendasSecao[0] && !carregando" fixed-header height="100vh">
              <thead ref="thead">
                <tr class="border-line-head">
                  <th class="fixed-column-header white--text text-center" nowrap>
                    <v-row class="pb-0" no-gutters>
                      <v-col cols="1" class="pa-0" style="display: flex; justify-content: start; align-items: start;">
                        <v-row class="pb-0" no-gutters>
                          <div>
                            <v-tooltip bottom color="error" class="text-no-wrap">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="dialog = false" elevation="0"
                                  class="red ml-n4 pa-0" x-small style="border-radius: 2px;" height="28px" dark><v-icon
                                    size="20px" class="mt-1">mdi-arrow-left-bold</v-icon></v-btn>
                              </template>
                              <span>Fechar tela cheia</span>
                            </v-tooltip>
                          </div>
                          <div>
                            <v-tooltip bottom color="primary" class="text-no-wrap">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click="sheet = !sheet" elevation="0"
                                  class="primary ml-n4 pa-0" x-small style="border-radius: 2px;" height="30px"
                                  dark><v-icon small class="mt-1">mdi-filter-menu</v-icon></v-btn>
                              </template>
                              <span>Menu Filtros</span>
                            </v-tooltip>
                          </div>
                        </v-row>
                      </v-col>
                      <v-col cols="11" class="pa-0"
                        style="display: flex; justify-content: center; align-items: center;">
                        <div class="mr-9 text-subtitle-2 font-weight-medium">
                          DATA
                        </div>
                      </v-col>
                    </v-row>
                  </th>
                  <th class="text-center white--text text-subtitle-2 font-weight-medium" nowrap
                    v-for="st in codSecoesArray" :key="st">{{ tituloSecoes[st] }}</th>
                  <th class="text-center white--text text-subtitle-2 font-weight-medium barra-total-left" nowrap>TOTAL
                  </th>
                </tr>
              </thead>
              <tbody v-if="vendasSecao[0]">
                <tr v-for="(u, i) in vendasSecao" :key="i" class="border-line">
                  <td class="px-2 text-center fixed-column" nowrap> {{ u.dtmovimento }}</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="u.secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ u.secao[s] | formataDinheiroTabela
                        }}
                      </span>
                    </div>
                  </td>
                  <td class="px-2 text-right barra-total-left column-totais" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ u.totaldia | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="totalizadoresSecao.vendas[0]">
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <!--
                <tr v-if="totalizadoresSecao.variacoes.vendaLiquida[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total Venda Liquida</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.variacoes.vendaLiquida[0].secao[s] | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.variacoes.vendaLiquida[0].totalvendaliquida | formataDinheiroTabela }}
                      </span>
                    </div>
                  </td>
                </tr> Total Venda Liquida -->
                <tr v-if="totalizadoresSecao.vendas[0]" class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total Venda Bruta</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.vendas[0].totalsecaoempresa < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendas[0].totalsecaoempresa
                        |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr><!-- Total Venda Bruta -->
                <tr v-if="totalizadoresSecao.perparticSecao[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Partic. Seção</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.perparticSecao[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.perparticSecao[0].totalsecaoempresa.toFixed(2) }}%
                  </td>
                </tr><!-- % Partic. Seção -->
                <!--
                <tr v-if="totalizadoresSecao.perparticRegiao[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Partic. Regional</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.perparticRegiao[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.perparticRegiao[0].totalpartcregionalgeral.toFixed(2) }}%
                  </td>
                </tr> % Partic. Regional -->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <!--
                <tr v-if="totalizadoresSecao.variacoes.vendaAnoAnt[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Ano Ant.</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.variacoes.vendaAnoAnt[0].secao[s] | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.variacoes.vendaAnoAnt[0].totalvendalastyear | formataDinheiroTabela }}
                      </span>
                    </div>
                  </td>
                </tr> Venda Liq. Ano Ant. -->
                <tr v-if="totalizadoresSecao.variacoes.variacaoNominal[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Variação Nominal</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-chip small label
                      :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].secao[s])">
                      {{ totalizadoresSecao.variacoes.variacaoNominal[0].secao[s].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center" nowrap>
                    <v-chip small label
                      :class="coresVarariacoes(totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal)">
                      {{ totalizadoresSecao.variacoes.variacaoNominal[0].variacaonominal.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr><!-- % Variação Nominal -->
                <!--
                <tr v-if="totalizadoresSecao.variacoes.vendaMesAnt[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Venda Liq. Mês Ant.</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.variacoes.vendaMesAnt[0].secao[s] | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.variacoes.vendaMesAnt[0].totalvendalastmonth | formataDinheiroTabela }}
                      </span>
                    </div>
                  </td>
                </tr> Venda Liq. Mês Ant. -->
                <tr v-if="totalizadoresSecao.variacoes.variacao[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Variação Mês Ant.</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].secao[s])">
                      {{ totalizadoresSecao.variacoes.variacao[0].secao[s].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresVarariacoes(totalizadoresSecao.variacoes.variacao[0].variacao)">
                      {{ totalizadoresSecao.variacoes.variacao[0].variacao.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr><!-- % Variação Mês Ant. -->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <!--
                <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        Vendas de Obsoletos (R$)
                        <v-icon v-bind="attrs" v-on="on" small color="red"
                          class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <span>Apoio a decisão</span>
                    </v-tooltip>
                  </td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.vendasObsoletos[0].secoes[s]
                        |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.vendasObsoletos[0].total_venda_obsoleto | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr> Vendas de Obsoletos (R$) -->
                <!--
                <tr v-if="totalizadoresSecao.vendasObsoletos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Margem de Obsoletos (%)</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.vendasObsoletos[0].margens[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.vendasObsoletos[0].margem_venda_obsoleto.toFixed(2) }}%
                  </td>
                </tr>  Margem de Obsoletos (%) -->
                <!--
                <tr v-if="totalizadoresSecao.estoqueObsoletos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Estoque de Obsoletos</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.estoqueObsoletos[0].secoes[s] | formataDinheiro }}
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.estoqueObsoletos[0].total_estoque | formataDinheiro }}
                  </td>
                </tr>   Estoque de Obsoletos
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr> -->
                <tr v-if="totalizadoresSecao.devolucao[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total Devoluções (R$)</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.devolucao[0].totaldevolucao
                        |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr><!-- Total Devoluções (R$) -->
                <tr v-if="totalizadoresSecao.perDevolucao[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Total Devoluções (%)</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.perDevolucao[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.perDevolucao[0].totaldevolucao.toFixed(2) }}%
                  </td>
                </tr><!--  Total Devoluções (%) -->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <tr v-if="totalizadoresSecao.descontos.desconto[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (R$)</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.descontos.desconto[0].secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.descontos.desconto[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="vendasSecao.total < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.descontos.desconto[0].totaldesconto | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr><!-- Total Desconto -->
                <tr v-if="totalizadoresSecao.descontos.perdesconto[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Descontos (%)</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.descontos.perdesconto[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.descontos.perdesconto[0].totaldesconto.toFixed(2) }}%
                  </td>
                </tr><!-- Total de Descontos (%) -->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <tr v-if="totalizadoresSecao.margemMeta[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Margem Meta</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.margemMeta[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.margemMeta[0].totalmargemmetafilial.toFixed(2) }}%
                  </td>
                </tr> <!--  Margem Meta -->
                <tr v-if="totalizadoresSecao.margem[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Margem Realizada Acum.</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].secao[s])">
                      {{ totalizadoresSecao.margem[0].secao[s].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center" nowrap>
                    <v-chip small label :class="coresMargem(totalizadoresSecao.margem[0].totalmargemgeral)">
                      {{ totalizadoresSecao.margem[0].totalmargemgeral.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr><!-- Margem Realizada Acum. -->
                <tr v-if="totalizadoresSecao.margemAnoAnt[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Margem Ano Anterior</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-chip small label :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].secao[s])">
                      {{ totalizadoresSecao.margemAnoAnt[0].secao[s].toFixed(2) }}%
                    </v-chip>
                  </td>
                  <td class="px-2 text-center" nowrap>
                    <v-chip small label
                      :class="coresMargem(totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral)">
                      {{ totalizadoresSecao.margemAnoAnt[0].totalmargemlastyeargeral.toFixed(2) }}%
                    </v-chip>
                  </td>
                </tr><!-- Margem Ano Anterior -->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Entrada Ajuste de Estoque</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.entradasSaidasAvulsa[0].lojasent[s] | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.entradasSaidasAvulsa[0].totalentrada | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr><!--  Entrada Ajuste de Estoque -->
                <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Saída Ajuste de Estoque</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.entradasSaidasAvulsa[0].lojassaid[s] | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.entradasSaidasAvulsa[0].totalsaida | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr><!--  Saída Ajuste de Estoque -->
                <tr v-if="totalizadoresSecao.entradasSaidasAvulsa[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Saldo de Ajustes</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.entradasSaidasAvulsa[0].saldo[s]
                        | formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.entradasSaidasAvulsa[0].totalsaldo | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr><!--  Saldo de Ajustes -->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <tr v-if="totalizadoresSecao.estoqueAnoAnt[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Estoque Ano Anterior</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.estoqueAnoAnt[0].secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueAnoAnt[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.estoqueAnoAnt[0].totalestoqueinicial | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr><!--  Estoque Ano Anterior -->
                <tr v-if="totalizadoresSecao.diasEstoqueFuturo[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Dias de Estoque - Futuro</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.diasEstoqueFuturo[0].secao[s] }}
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.diasEstoqueFuturo[0].totaldiasestoquefuturofilial }}
                  </td>
                </tr><!--  Dias de Estoque - Futuro - Em desenvolvimento ...-->
                <tr v-if="totalizadoresSecao.diasEstoqueReal[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column">Dias de Estoque - Real</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.diasEstoqueReal[0].secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.diasEstoqueReal[0].secao[s]
                        |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.diasEstoqueReal[0].totaldiasestoquerealfilial | formataDinheiroTabela }}
                      </span>
                    </div>
                  </td>
                </tr><!--  Dias de Estoque - Real - Em desenvolvimento ...-->
                <tr v-if="totalizadoresSecao.estoqueInicial[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Estoque Inicial</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.estoqueInicial[0].secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueInicial[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.estoqueInicial[0].totalestoqueinicial < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.estoqueInicial[0].totalestoqueinicial | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr><!-- Estoque Inicial -->
                <!--
                <tr v-if="totalizadoresSecao.movimentacoes[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Movimentações</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.movimentacoes[0].secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.movimentacoes[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.movimentacoes[0].totalmovimentacoes < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.movimentacoes[0].totalmovimentacoes | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr> Movimentacoes -->
                <tr v-if="totalizadoresSecao.estoqueFinal[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Estoque Final</td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.estoqueFinal[0].secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.estoqueFinal[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.estoqueFinal[0].totalestoquefinal < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.estoqueFinal[0].totalestoquefinal | formataDinheiroTabela }}</span>
                    </div>
                  </td>
                </tr><!-- Estoque Final -->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
                <tr v-if="totalizadoresSecao.pedidos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        Saldo Pedidos de Compra (D-1)
                        <v-icon v-bind="attrs" v-on="on" small color="primary"
                          class="mt-n1">mdi-information-outline</v-icon>
                      </template>
                      <span>Custo Última Compra</span>
                    </v-tooltip>
                  </td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.pedidos[0].secao[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].secao[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.pedidos[0].totalpedido < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{ totalizadoresSecao.pedidos[0].totalpedido |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!--  Saldo Pedidos -->
                <tr v-if="totalizadoresSecao.mercadoriasRecebidas.secoes[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>
                    <template>
                      Mercadorias Recebidas (Realtime)
                    </template>
                  </td>
                  <td class="px-2 text-right" nowrap v-for="s in secoesArray" :key="s">
                    <div class="espaco-dinheiro"
                      :style="totalizadoresSecao.mercadoriasRecebidas.secoes[0].secoes[s] < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.mercadoriasRecebidas.secoes[0].secoes[s] |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                  <td class="px-2 text-right" nowrap>
                    <div style="display: flex; justify-content: space-between; align-items: center;"
                      :style="totalizadoresSecao.mercadoriasRecebidas.secoes[0].totalmrsecao < 0 ? 'color:red;' : ''">
                      <span style="margin-right: 5px;">R$</span><span>{{
                        totalizadoresSecao.mercadoriasRecebidas.secoes[0].totalmrsecao |
                        formataDinheiroTabela }} </span>
                    </div>
                  </td>
                </tr> <!-- Mercadorias Recebidas -->
                <tr v-if="totalizadoresSecao.itensAtivos[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's em Estoque</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.itensAtivos[0].secao[s] }}
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.itensAtivos[0].totalitensativosfilial }}
                  </td>
                </tr> <!--  Total de Sku's em Estoque -->
                <tr v-if="totalizadoresSecao.itensEncalhados[0] && !carregandoTotais"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's Encalhados</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.itensEncalhados[0].secao[s] }}
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.itensEncalhados[0].totalitensencalhadosfilial.toFixed(2) }}%
                  </td>
                </tr> <!--  Sku's Encalhados -->
                <tr v-if="totalizadoresSecao.rupturaA[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva A</td>
                  <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'RA', '')"
                        class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadoresSecao.rupturaA[0].secao[s] }}
                      </v-btn>
                    </v-hover>
                  </td>
                  <td class="px-0 text-center" nowrap>
                    {{ totalizadoresSecao.rupturaA[0].totalrupturaafilial }}
                  </td>
                </tr><!-- Sku's em Ruptura da Curva A-->
                <tr v-if="totalizadoresSecao.curvaA[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A</td>
                  <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'CA', '')"
                        class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadoresSecao.curvaA[0].secao[s].toFixed(0) }}
                      </v-btn>
                    </v-hover>
                  </td>
                  <td class="px-0 text-center" nowrap>
                    {{ totalizadoresSecao.curvaA[0].curvaa.toFixed(0) }}
                  </td>
                </tr><!-- Total de Sku's da Curva A-->
                <tr v-if="totalizadoresSecao.perCurvaA[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva A</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.perCurvaA[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-0 text-center" nowrap>
                    {{ totalizadoresSecao.perCurvaA[0].TotalPerccurvaa.toFixed(2) }}%
                  </td>
                </tr><!-- % Ruptura da Curva A-->
                <tr v-if="totalizadoresSecao.rupturaBC[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva B/C</td>
                  <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'RB', '')"
                        class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadoresSecao.rupturaBC[0].secao[s] }}
                      </v-btn>
                    </v-hover>
                  </td>
                  <td class="px-0 text-center" nowrap>
                    {{ totalizadoresSecao.rupturaBC[0].totalrupturaBcfilial }}
                  </td>
                </tr><!-- Sku's em Ruptura da Curva B/C-->
                <tr v-if="totalizadoresSecao.curvaBC[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva B/C</td>
                  <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                    <v-hover v-slot="{ hover }">
                      <v-btn text width="100%" @click="detalharRupturaEmpresa(validarSecao(s), 'CB', '')"
                        class="botao_detalhe">
                        <v-icon v-if="hover" color="primary" class="ml-n4">mdi-magnify-plus-outline</v-icon>
                        {{ totalizadoresSecao.curvaBC[0].secao[s].toFixed(0) }}
                      </v-btn>
                    </v-hover>
                  </td>
                  <td class="px-0 text-center" nowrap>
                    {{ totalizadoresSecao.curvaBC[0].Totalcurvabc.toFixed(0) }}
                  </td>
                </tr><!-- Total de Sku's da Curva B/C-->
                <tr v-if="totalizadoresSecao.perCurvaBC[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% Ruptura da Curva B/C</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.perCurvaBC[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.perCurvaBC[0].Totalcurvabc.toFixed(2) }}%
                  </td>
                </tr><!-- % Ruptura da Curva B/C-->
                <tr v-if="totalizadoresSecao.rupturaGeral[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Sku's em Ruptura da Curva (A,B,C)</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.rupturaGeral[0].secao[s] }}
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.rupturaGeral[0].totalruptura }}
                  </td>
                </tr><!-- Sku's em Ruptura da Curva (A,B,C)-->
                <tr v-if="totalizadoresSecao.curvaGeral[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>% de Ruptura Total (A,B,C)</td>
                  <td class="px-2 text-center" nowrap v-for="s in secoesArray" :key="s">
                    {{ totalizadoresSecao.curvaGeral[0].secao[s].toFixed(2) }}%
                  </td>
                  <td class="px-2 text-center" nowrap>
                    {{ totalizadoresSecao.curvaGeral[0].totalcurvafilialloja.toFixed(2) }}%
                  </td>
                </tr><!-- % de Ruptura Total (A,B,C)-->
                <tr v-if="totalizadoresSecao.curvaBC[0] && !carregandoTotais2"
                  class="border-line-foot font-weight-medium">
                  <td class="text-left py-1 fixed-column" nowrap>Total de Sku's da Curva A/B/C</td>
                  <td class="px-0 text-center" nowrap v-for="s in secoesArray" :key="s">
                        {{ (totalizadoresSecao.curvaBC[0].secao[s] + totalizadoresSecao.curvaA[0].secao[s]).toFixed(0) }}
                  </td>
                  <td class="px-0 text-center" nowrap>
                    {{ (totalizadoresSecao.curvaBC[0].Totalcurvabc + totalizadoresSecao.curvaA[0].curvaa).toFixed(0) }}
                  </td>
                </tr><!-- Total de Sku's da Curva A + B/C-->
                <tr class="barra-total-top">
                  <td colspan="100%"></td>
                </tr>
              </tfoot>
            </v-simple-table>
            <v-alert class="error mb-0" v-else-if="!carregando">Nenhum registro encontrado.</v-alert>
            <v-skeleton-loader light v-if="carregando || carregandoTotais || carregandoTotais2" type="table-tfoot" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais || carregandoTotais2" type="table-tfoot" />
            <v-skeleton-loader light v-if="carregando || carregandoTotais || carregandoTotais2" type="table-tfoot" />
          </v-card-text>
        </v-sheet>
      </v-card>
    </v-dialog>

    <!-- DETALHAMENTO DE CAMPOS -->
    <v-dialog v-model="dialogDetalheRuptura" scrollable width="1300px">
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span v-if="tipoRuptura == 'RA'">Sku's em Ruptura da Curva A ({{ !busca.idempresa ? nomeEmpresaDialog :
            empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <span v-else-if="tipoRuptura == 'CA'">Total de Sku's da Curva A ({{ !busca.idempresa ? nomeEmpresaDialog :
            empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <span v-else-if="tipoRuptura == 'RB'">Total de Sku's da Curva B/C ({{ !busca.idempresa ? nomeEmpresaDialog :
            empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <span v-else-if="tipoRuptura == 'CB'">% Ruptura da Curva B/C ({{ !busca.idempresa ? nomeEmpresaDialog :
            empresasTitulo.filter((v) => v.idfilial == busca.idempresa).map((u) => u.filial)[0] }})</span>
          <span v-else-if="tipoRuptura == 'A'">Geral Curva A</span>
          <span v-else-if="tipoRuptura == 'B'">Geral Curva B</span>
          <span v-else-if="tipoRuptura == 'C'">Geral Curva C</span>
          <span v-else-if="tipoRuptura == 'T'">Total Curva</span>
          <v-spacer />
          <v-btn height="30" text x-small @click="dialogDetalheRuptura = false" dark>
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-skeleton-loader v-if="carregando3" type="table-tbody" />
          <div v-else>
          <v-data-table v-if="tipoRuptura == 'RA'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
            :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'idsubproduto', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'produto', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'qtdestoque', align: 'center ml-4', class: 'pr-0 text-truncate' },
              { text: 'Qtd. Estoque CD', value: 'qtdestoque39', align: 'center ml-4', class: 'pr-0 text-truncate' },
              { text: 'Pedidos Pendentes', value: 'pedidospendentes', align: 'center ml-4', class: 'pr-0 text-truncate' },
              { text: 'Cód. Fornecedor', value: 'codigofornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Descrição Fornecedor', value: 'fornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Compradores', value: 'comprador', class: 'text-no-wrap' },
            ]" :items="detalheRuptura ? detalheRuptura.map((u) => {
              return {
                idsubproduto: u.idsubproduto,
                produto: u.produto,
                qtdestoque: u.qtdestoque,
                qtdestoque39: u.qtdestoque39,
                pedidospendentes: u.pedidospendentes,
                codigofornecedor: u.codigofornecedor,
                fornecedor: u.fornecedor,
                comprador: u.comprador,
              };
            }) : []">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.idsubproduto }}</td>
                <td class="text-left" nowrap> {{ item.produto }} </td>
                <td class="text-center" nowrap> {{ item.qtdestoque }}</td>
                <td class="text-center" nowrap> {{ item.qtdestoque39 }}</td>
                <td class="text-center" nowrap> {{ item.pedidospendentes }}</td>
                <td class="text-left" nowrap> {{ item.codigofornecedor }}</td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.fornecedor | formataTextoCurto }}</span>
                    </template>
                    <span>{{ item.fornecedor }}</span>
                  </v-tooltip>
                </td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.comprador | formataTextoPedido }}</span>
                    </template>
                    <span>{{ item.comprador }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if="tipoRuptura == 'RB'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
            :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'idsubproduto', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'produto', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'qtdestoque', align: 'center ml-4', class: 'pr-0 text-truncate' },
              { text: 'Cód. Fornecedor', value: 'codigofornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Descrição Fornecedor', value: 'fornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Compradores', value: 'comprador', class: 'text-no-wrap' },
            ]" :items="detalheRuptura ? detalheRuptura.map((u) => {
              return {
                idsubproduto: u.idsubproduto,
                produto: u.produto,
                qtdestoque: u.qtdestoque,
                codigofornecedor: u.codigofornecedor,
                fornecedor: u.fornecedor,
                comprador: u.comprador,
              };
            }) : []">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.idsubproduto }}</td>
                <td class="text-left" nowrap> {{ item.produto }} </td>
                <td class="text-center" nowrap> {{ item.qtdestoque }}</td>
                <td class="text-left" nowrap> {{ item.codigofornecedor }}</td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.fornecedor | formataTextoCurto }}</span>
                    </template>
                    <span>{{ item.fornecedor }}</span>
                  </v-tooltip>
                </td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.comprador | formataTextoPedido }}</span>
                    </template>
                    <span>{{ item.comprador }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>

          </v-data-table>
          <v-data-table v-if="tipoRuptura == 'CA'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
            :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'idsubproduto', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'produto', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'qtdestoque', align: 'center ml-4', class: 'pr-0 text-truncate' },
              { text: 'Cód. Fornecedor', value: 'codigofornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Descrição Fornecedor', value: 'fornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Compradores', value: 'comprador', class: 'text-no-wrap' },
            ]" :items="detalheRuptura ? detalheRuptura.map((u) => {
              return {
                idsubproduto: u.idsubproduto,
                produto: u.produto,
                qtdestoque: u.qtdestoque,
                codigofornecedor: u.codigofornecedor,
                fornecedor: u.fornecedor,
                comprador: u.comprador,
              };
            }) : []">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.idsubproduto }}</td>
                <td class="text-left" nowrap> {{ item.produto }} </td>
                <td class="text-center" nowrap> {{ item.qtdestoque }}</td>
                <td class="text-left" nowrap> {{ item.codigofornecedor }}</td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.fornecedor | formataTextoCurto }}</span>
                    </template>
                    <span>{{ item.fornecedor }}</span>
                  </v-tooltip>
                </td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.comprador | formataTextoPedido }}</span>
                    </template>
                    <span>{{ item.comprador }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>

          </v-data-table>
          <v-data-table v-if="tipoRuptura == 'CB'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
            :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'idsubproduto', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'produto', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'qtdestoque', align: 'center ml-4', class: 'pr-0 text-truncate' },
              { text: 'Cód. Fornecedor', value: 'codigofornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Descrição Fornecedor', value: 'fornecedor', class: 'pr-0 text-no-wrap' },
              { text: 'Compradores', value: 'comprador', class: 'text-no-wrap' },
            ]" :items="detalheRuptura ? detalheRuptura.map((u) => {
              return {
                idsubproduto: u.idsubproduto,
                produto: u.produto,
                qtdestoque: u.qtdestoque,
                codigofornecedor: u.codigofornecedor,
                fornecedor: u.fornecedor,
                comprador: u.comprador,
              };
            }) : []">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.idsubproduto }}</td>
                <td class="text-left" nowrap> {{ item.produto }} </td>
                <td class="text-center" nowrap> {{ item.qtdestoque }}</td>
                <td class="text-left" nowrap> {{ item.codigofornecedor }}</td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.fornecedor | formataTextoCurto }}</span>
                    </template>
                    <span>{{ item.fornecedor }}</span>
                  </v-tooltip>
                </td>
                <td class="text-left" nowrap>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{ item.comprador | formataTextoPedido }}</span>
                    </template>
                    <span>{{ item.comprador }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>

          </v-data-table>
          <v-data-table v-if="tipoRuptura == 'A'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
                        :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'CODIGO', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'PRODUTO', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'ESTOQUE', align: 'center ml-4', class: 'pr-0 text-truncate' },
            ]" :items="detalheRuptura">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.CODIGO }}</td>
                <td class="text-left" nowrap> {{ item.PRODUTO }} </td>
                <td class="text-center" nowrap> {{ item.ESTOQUE }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if="tipoRuptura == 'B'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
                        :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'CODIGO', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'PRODUTO', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'ESTOQUE', align: 'center ml-4', class: 'pr-0 text-truncate' },
            ]" :items="detalheRuptura">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.CODIGO }}</td>
                <td class="text-left" nowrap> {{ item.PRODUTO }} </td>
                <td class="text-center" nowrap> {{ item.ESTOQUE }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if="tipoRuptura == 'C'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
                        :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'CODIGO', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'PRODUTO', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'ESTOQUE', align: 'center ml-4', class: 'pr-0 text-truncate' },
            ]" :items="detalheRuptura">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.CODIGO }}</td>
                <td class="text-left" nowrap> {{ item.PRODUTO }} </td>
                <td class="text-center" nowrap> {{ item.ESTOQUE }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if="tipoRuptura == 'T'" :height="detalheRuptura ? (detalheRuptura.length > 14 ? '70vh' : '100%') : '100%'" fixed-header
                        :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'CODIGO', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'PRODUTO', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'ESTOQUE', align: 'center ml-4', class: 'pr-0 text-truncate' },
            ]" :items="detalheRuptura">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.CODIGO }}</td>
                <td class="text-left" nowrap> {{ item.PRODUTO }} </td>
                <td class="text-center" nowrap> {{ item.ESTOQUE }}</td>
              </tr>
            </template>
          </v-data-table>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetalhePedidos" scrollable width="1300px">
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span>Pedidos de compra pendentes - ({{ nomeEmpresaDialog }})</span>
          <v-spacer />
          <v-btn height="30" text x-small @click="dialogDetalhePedidos = false" dark>
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table v-if="detalhePedidos[0]" :headers="[
            { text: 'N° pedido', value: 'idpedido', class: 'text-no-wrap' },
            { text: 'Data', value: 'dtpedido', class: 'text-no-wrap' },
            { text: 'Código', value: 'idclifor', class: 'text-no-wrap' },
            { text: 'Fornecedor', value: 'cliente', class: 'text-no-wrap' },
            { text: 'Usuário', value: 'usuario', class: 'text-no-wrap' },
            { text: 'Total Solicitado', value: 'qtdsolicitada', class: 'text-no-wrap' },
            { text: 'Total Atendido', value: 'qtdatendida', class: 'text-no-wrap' },
            { text: 'Total Pendente', value: 'qtdpendente', class: 'text-no-wrap' },
            { text: 'Total Valor', value: 'Valor', class: 'text-no-wrap' },
          ]" :items="detalhePedidos" :expanded.sync="expanded" expand-icon="mdi-menu-down" item-key="idpedido"
            class="elevation-0 table_clicavel" :items-per-page="50" @click:row="handleRowClick" single-expand
            :item-class="rowClass" mobile-breakpoint="0" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-chevron-left',
              nextIcon: 'mdi-chevron-right',
              itemsPerPageText: 'Pedidos por página',
              'items-per-page-options': [30, 50, 100, 500],
              disableItemsPerPage: false,
            }">
            <template v-slot:item.dtpedido="{ item }">
              {{ item.dtpedido || 0 | formatDate }}
            </template>

            <template v-slot:item.cliente="{ item }">
              <span class="text-no-wrap">{{ item.cliente || '' | formataTextoPedido }}</span>
            </template>

            <template v-slot:item.qtdsolicitada="{ item }">
              {{ item.qtdsolicitada || 0 | formataQtdProduto }}
            </template>

            <template v-slot:item.qtdatendida="{ item }">
              {{ item.qtdatendida || 0 | formataQtdProduto }}
            </template>

            <template v-slot:item.qtdpendente="{ item }">
              {{ item.qtdpendente || 0 | formataQtdProduto }}
            </template>

            <template v-slot:item.Valor="{ item }">
              {{ item.Valor || 0 | formataDinheiro }}
            </template>

            <template v-slot:expanded-item="{}">
              <td colspan="10" class="px-0 primary">
                <v-simple-table class="table">
                  <thead class="header">
                    <tr class="cursor-normal">
                      <th nowrap>Cód. produto</th>
                      <th nowrap>Descrição produto</th>
                      <th nowrap>Valor</th>
                      <th nowrap>Qtd. solicitada</th>
                      <th nowrap>Qtd. atendida</th>
                      <th nowrap>Qtd. pendente</th>
                    </tr>
                  </thead>
                  <tbody class="body text-capitalize">
                    <tr v-if="carregando2">
                      <td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td>
                    </tr>
                    <tr v-for="(item, index) in detalheProdutos" :key="index" class="cursor-normal" v-else>
                      <td nowrap>{{ item.idsubproduto }}</td>
                      <td nowrap>{{ item.produto | formataTextoPedidoConcreto }}</td>
                      <td nowrap>{{ item.Valor | formataDinheiro }}</td>
                      <td nowrap>{{ item.qtdsolicitada | formataQtdProduto }}</td>
                      <td nowrap>{{ item.qtdatendida | formataQtdProduto }}</td>
                      <td nowrap>{{ item.qtdpendente | formataQtdProduto }}</td>
                    </tr>
                  </tbody>
                  <tfoot class="foot text-capitalize" v-if="detalheProdutos[0] && !carregando2">
                    <tr class="cursor-normal">
                      <td nowrap></td>
                      <td nowrap></td>
                      <td nowrap>{{ detalheProdutos.map(v => v.Valor).reduce((a, v) => a + v) | formataDinheiro }}</td>
                      <td nowrap>{{ (detalheProdutos.map(v => v.qtdsolicitada).reduce((a, v) => a + v)) |
                        formataQtdProduto
                        }}</td>
                      <td nowrap>{{ (detalheProdutos.map(v => v.qtdatendida).reduce((a, v) => a + v)) |
                        formataQtdProduto
                        }}</td>
                      <td nowrap>{{ (detalheProdutos.map(v => v.qtdpendente).reduce((a, v) => a + v)) |
                        formataQtdProduto
                        }}</td>
                    </tr>
                  </tfoot>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetalheMercadoriasRecebidas" scrollable width="1300px">
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span>Mercadorias Recebidas por Comprador - ({{ nomeEmpresaDialog }})</span>
          <v-spacer />
          <v-btn height="30" text x-small @click="dialogDetalheMercadoriasRecebidas = false" dark>
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-expansion-panels v-model="panel" accordion v-if="detalheMercadorias.compradores.lista != null">
            <v-expansion-panel v-for="(item, i) in detalheMercadorias.compradores.lista" :key="i"
              active-class="primary white--text">
              <v-expansion-panel-header @click="listarSecoesCompradores(item.idcomprador)" hide-actions>
                <v-row align="center" no-gutters>
                  <v-col cols="2" class="text-start font-weight-bold"> <br />
                    <template class="mt-1">
                      {{ item.comprador }}
                    </template>
                  </v-col>
                  <v-col cols="10">
                    <v-row>
                      <v-col class="text-center font-weight-bold">
                        <v-span> Valor </v-span> <br />
                        <template class="pt-2">
                          {{
                            item.valor | formataDinheiro
                          }}
                        </template>
                      </v-col>
                      <v-col class="text-center font-weight-bold">
                        <v-span> Orçamento </v-span> <br />
                        <template class="pt-2">
                          {{
                            item.meta | formataDinheiro
                          }}
                        </template>
                      </v-col>
                      <v-col class="text-center font-weight-bold">
                        <v-span> Percentual de Participação </v-span> <br />
                        <template class="pt-2">
                          {{
                            (item.perating || 0).toFixed(2)
                          }}%
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-skeleton-loader v-if="carregando3" type="table-tbody" />
                <v-card-text v-else class="elevation-0 pa-0">
                  <v-data-table v-if="detalheMercadorias.secoes.lista" :headers="[
                    { text: 'Cod', value: 'idsecao', class: 'text-no-wrap' },
                    { text: 'Seção', value: 'secao', class: 'text-no-wrap' },
                    { text: 'Valor', value: 'valor', class: 'text-no-wrap' },
                    { text: 'Orçamento', value: 'meta', class: 'text-no-wrap' },
                    { text: 'Percentual de Participação', value: 'perating', class: 'text-no-wrap' },
                  ]" :items="detalheMercadorias.secoes.lista" item-key="idsecao" class="elevation-0 table_clicavel"
                    :expanded.sync="expanded2" expand-icon="mdi-menu-down" @click:row="handleRowClick2" single-expand
                    fixed-header :item-class="rowClass2" :items-per-page="50" mobile-breakpoint="0" :footer-props="{
                      showFirstLastPage: true,
                      prevIcon: 'mdi-chevron-left',
                      nextIcon: 'mdi-chevron-right',
                      itemsPerPageText: 'Mercadorias por página',
                      'items-per-page-options': [30, 50, 100, 500],
                      disableItemsPerPage: false,
                    }">
                    <template v-slot:item.idsecao="{ item }">
                      {{ item.idsecao }}
                    </template>

                    <template v-slot:item.secao="{ item }">
                      <span class="text-no-wrap">{{ item.secao }}</span>
                    </template>

                    <template v-slot:item.valor="{ item }">
                      {{ item.valor | formataDinheiro }}
                    </template>

                    <template v-slot:item.meta="{ item }">
                      {{ item.meta | formataDinheiro }}
                    </template>

                    <template v-slot:item.perating="{ item }">
                      {{ (item.perating || 0).toFixed(2) }}%
                    </template>

                    <template v-slot:expanded-item="{}">
                      <td colspan="10" class="px-0 primary">
                        <v-simple-table class="table">
                          <thead class="header">
                            <tr class="cursor-normal">
                              <th nowrap>Cód. produto</th>
                              <th nowrap>Descrição produto</th>
                              <th nowrap>Qtd.</th>
                              <th nowrap>Valor</th>
                            </tr>
                          </thead>
                          <tbody class="body text-capitalize">
                            <tr v-if="carregando2">
                              <td colspan="100%"><v-skeleton-loader class="white" type="table-row" /></td>
                            </tr>
                            <tr v-for="(item, index) in detalheMercadorias.produtos.lista" :key="index"
                              class="cursor-normal" v-else>
                              <td nowrap>{{ item.idsubproduto }}</td>
                              <td nowrap>{{ item.produto | formataTextoPedidoConcreto }}</td>
                              <td nowrap>{{ item.qtd | formataQtdProduto }}</td>
                              <td nowrap>{{ item.valor | formataDinheiro }}</td>
                            </tr>
                          </tbody>
                          <tfoot class="foot text-capitalize" v-if="detalheMercadorias.produtos && !carregando2">
                            <tr class="cursor-normal">
                              <td nowrap></td>
                              <td nowrap></td>
                              <td nowrap></td>
                              <td nowrap>{{ detalheMercadorias.produtos.total | formataDinheiro }}</td>
                            </tr>
                          </tfoot>
                        </v-simple-table>
                      </td>
                    </template>

                    <template #body.append>
                      <tr>
                        <td class="footer"><strong>Total:</strong></td>
                        <td colspan="1"></td>
                        <td class="footer"><strong>{{ (detalheMercadorias.secoes.total || 0).toFixed(2) |
                          formataDinheiro }}</strong></td>
                        <td class="footer"><strong>{{ (detalheMercadorias.secoes.totalmeta || 0).toFixed(2) |
                          formataDinheiro }}</strong></td>
                        <td class="footer"><strong>{{ (detalheMercadorias.secoes.totalperc || 0).toFixed(2) }}%</strong>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header hide-actions>
                <v-row align="center" no-gutters>
                  <v-col cols="2" class="text-start font-weight-bold"> <br />
                    <template class="mt-1">
                      TOTAIS
                    </template>
                  </v-col>
                  <v-col cols="10">
                    <v-row>
                      <v-col class="text-center font-weight-bold">
                        <v-span> Valor Filial </v-span> <br />
                        <template class="pt-2">
                          {{
                            (detalheMercadorias.compradores.total || 0) | formataDinheiro
                          }}
                        </template>
                      </v-col>
                      <v-col class="text-center font-weight-bold">
                        <v-span> </v-span> <br />
                        <template class="pt-2">
                          {{

                          }}
                        </template>
                      </v-col>
                      <v-col class="text-center font-weight-bold">
                        <v-span> Percentual de Participação </v-span> <br />
                        <template class="pt-2">
                          {{
                            (detalheMercadorias.compradores.totalperc || 0).toFixed(2)
                          }}%
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels v-else>
            <v-expansion-panel>
              <v-expansion-panel-header hide-actions>
                <v-row align="center" no-gutters>
                  <v-col cols="12" class="text-center font-weight-bold"> <br />
                    <template class="mt-1">
                      Nenhum registro encontrado.
                    </template>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetalheObsoletos" scrollable width="1300px">
      <v-card class="primary">
        <v-card-title class="pl-4 pr-3 white--text">
          <span>Itens Obsoletos - ({{ nomeEmpresaDialog }})</span>
          <v-spacer />
          <v-btn height="30" text x-small @click="dialogDetalheObsoletos = false" dark>
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-data-table :height="detalheObsoletos ? (detalheObsoletos.length > 14 ? '70vh' : '100%') : '100%'"
            fixed-header :items-per-page="30" mobile-breakpoint="0" :headers="[
              { text: 'Cód. Produto', value: 'idsubproduto', class: 'pr-0 text-trucate' },
              { text: 'Descrição Produto', value: 'produto', class: 'pr-0 text-no-wrap' },
              { text: 'Qtd. Estoque', value: 'qtdestoque', align: 'center ml-4', class: 'pr-0 text-truncate' },
              { text: 'Custo Médio', value: 'customedio', class: 'pr-0 text-no-wrap' },
            ]" :items="detalheObsoletos ? detalheObsoletos.map((u) => {
              return {
                idsubproduto: u.idsubproduto,
                produto: u.produto,
                qtdestoque: u.qtdestoque,
                customedio: u.customedio,
              };
            }) : []">
            <template v-slot:item="{ item }">
              <tr>
                <td class="text-left" nowrap> {{ item.idsubproduto }}</td>
                <td class="text-left" nowrap> {{ item.produto }} </td>
                <td class="text-center" nowrap> {{ item.qtdestoque | formataQtdProduto }}</td>
                <td class="text-left" nowrap> {{ item.customedio | formataDinheiro }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="carregandoTotais || carregandoTotais2 || carregandoTotais3" v-model="dialog" persistent
      overlay-opacity="0.8" width="300px">
      <v-card color="primary" dark>
        <v-card-text class="pa-5">
          <span v-if="carregandoTotais">Aguarde, vendas sendo carregadas.</span>
          <span v-else>Aguarde, totais sendo carregados.</span>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";

export default {
  name: "OnePage",
  mixins: [mixinFilial],
  components: {},
  data: () => ({
    carregando2: false,
    carregando3: false,
    expanded: [],
    expanded2: [],
    sheet: false,
    dialog: false,
    dataUltAtualizacao: '',
    carregandoTotais: true,
    carregandoTotais2: false,
    carregandoTotais3: true,
    carregando: true,
    filtroRegional: 0,
    periodos: [],
    regionais: [],
    secoes: [],
    vendasSecao: [],
    vendasLojas: [],
    totalizadores: {
      vendas: {
        perclojas: [],
        percpartregionais: [],
        totaloja: []
      },
      vendasObsoletos: [],
      metasMes: [],
      metasDia: [],
      metasVariacao: [],
      variacoes: [],
      devolucao: [],
      perDevolucao: [],
      desconto: [],
      metasDesconto: [],
      metasPerDesconto: [],
      margem: [],
      margemAcumulada:[],
      margemMeta: [],
      margemVenda: null,
      margemFatAnoAnt: null,
      diasEstoqueFuturo: [],
      diasEstoqueReal: [],
      estoque: [],
      estoqueAnoAnt: [],
      entradasSaidasAvulsa: [],
      pedidos: [],
      itensAtivos: [],
      itensEncalhados: [],
      estoqueObsoletos: [],
      ruptura: [],
      rupturaA: [],
      rupturaBC: [],
      rupturaGeral: [],
      totalRupturas: [],
      curva: [],
      curvaGeral: [],
      totalCurvas:[],
      percRupturas:[],
      mercadoriasRecebidas: [],
      transferencias: []
    },
    totalizadoresSecao: {
      vendas: [],
      vendasObsoletos: [],
      perparticSecao: [],
      perparticRegiao: [],
      variacoes: {
        vendaAnoAnt: [],
        variacaoNominal: [],
        vendaMesAnt: [],
        variacao: [],
        vendaLiquida: []
      },
      devolucao: [],
      perDevolucao: [],
      descontos: {
        desconto: [],
        perdesconto: []
      },
      margem: [],
      margemMeta: [],
      diasEstoqueFuturo: [],
      diasEstoqueReal: [],
      margemAnoAnt: [],
      estoqueAnoAnt: [],
      entradasSaidasAvulsa: [],
      estoqueInicial: [],
      movimentacoes: [],
      estoqueFinal: [],
      pedidos: [],
      itensAtivos: [],
      itensEncalhados: [],
      estoqueObsoletos: [],
      rupturaA: [],
      rupturaBC: [],
      rupturaGeral: [],
      curvaA: [],
      curvaBC: [],
      perCurvaA: [],
      perCurvaBC: [],
      curvaGeral: [],
      transferencias: []
    },
    stringDeEmpresas: '',
    busca: { idempresa: null, idsecao: null, periodo: { data: null } },
    dialogDetalheRuptura: false,
    dialogDetalhePedidos: false,
    dialogDetalheObsoletos: false,
    dialogDetalheMercadoriasRecebidas: false,
    dialogDetalheMercadoriasRecebidasEmpresa: false,
    detalheRuptura: [],
    detalhePedidos: [],
    detalheObsoletos: [],
    detalheProdutos: [],
    detalheProdutosSecoes: [],
    detalheMercadorias: {
      compradores: [],
      secoes: [],
      produtos: []
    },
    detalheSecoesCompradores: [],
    active: [],
    panel: false,
    open: false,
    idEmpresaDialog: 0,
    idSecaoDialog: 0,
    idCompradorDialog: 0,
    nomeSecaoDialog: '',
    nomeEmpresaDialog: '',
    tipoRuptura: '',
    empresasTitulo: [
      { "filial": "Ivaiporã", "idfilial": 1 },
      { "filial": "Pitanga", "idfilial": 2 },
      { "filial": "São João do Ivaí", "idfilial": 3 },
      { "filial": "Castelo", "idfilial": 4 },
      { "filial": "Candido", "idfilial": 5 },
      { "filial": "Apucarana", "idfilial": 7 },
      { "filial": "Jardim Alegre", "idfilial": 8 },
      { "filial": "Manoel Ribas", "idfilial": 9 },
      { "filial": "Faxinal", "idfilial": 10 },
      { "filial": "Campo Mourão", "idfilial": 11 },
      { "filial": "Cianorte", "idfilial": 14 },
      { "filial": "Paranavaí", "idfilial": 15 },
      { "filial": "Telêmaco", "idfilial": 16 },
      { "filial": "Roncador", "idfilial": 17 },
      { "filial": "Guarapuava", "idfilial": 18 },
      { "filial": "Palmital", "idfilial": 19 },
      { "filial": "Ibiporã", "idfilial": 20 },
      { "filial": "Turvo", "idfilial": 21 },
      { "filial": "São Mateus", "idfilial": 23 },
      { "filial": "São João do Triunfo", "idfilial": 24 },
      { "filial": "Pontal do Paraná", "idfilial": 26 },
      { "filial": "Mandaguari", "idfilial": 27 },
      { "filial": "Astorga", "idfilial": 28 },
      { "filial": "Cambé", "idfilial": 30 },
      { "filial": "Reserva", "idfilial": 31 },
      { "filial": "Rolândia", "idfilial": 35 },
      { "filial": "Maringá", "idfilial": 36 },
      { "filial": "Laranjeiras", "idfilial": 38 },
      { "filial": "E-commerce - CD", "idfilial": 39 },
      { "filial": "Castelo", "idfilial": 41 },
    ],
    lojas: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 14, 15, 16, 17, 18, 19, 20, 21, 23, 24, 26, 27, 28, 30, 31, 35, 36, 38, 39, 41],
    secoesArray: [
      'MATERIAL_BRUTO',
      'PISOS_REVESTIMENTOS',
      'QUÍMICOS_TINTAS_ACESSORIOS',
      'MATERIAL_ELETRICO',
      'ILUMINACAO',
      'HIDRAULICA',
      'PORTAS_JANELAS',
      'MATERIAL_SANITARIO',
      'LOUCAS',
      'MOVEIS_GABINETES',
      'FERRAGENS',
      'FERRAMENTAS',
      'UTILIDADES_DOMESTICAS',
      'JARDINAGEM_LAZER',
      'AQUECIMENTO_REFRIGERACAO',
      'MADEIRAS_COBERTURA',
      'FORA_LINHA',
      'CONCRETO',
      'ENERGIA_SOLAR',
      'OUTLET',
      'USO_CONSUMO'],
    codSecoesArray: [1001, 1002, 1003, 1004, 1005, 1006, 1007,
      1008, 1009, 1010, 1011, 1012, 1013, 1014, 1015, 1016, 1018, 1020, 1021, 1023, 1017,
    ],
    tituloSecoes: {
      1001: 'MATERIAL BRUTO',
      1002: 'PISOS REVESTIMENTOS',
      1003: 'QUÍMICOS TINTAS ACESSORIOS',
      1004: 'MATERIAL ELETRICO',
      1005: 'ILUMINAÇÃO',
      1006: 'HIDRÁULICA',
      1007: 'PORTAS JANELAS',
      1008: 'MATERIAL SANITÁRIO',
      1009: 'LOUÇAS',
      1010: 'MOVEIS GABINETES',
      1011: 'FERRAGENS',
      1012: 'FERRAMENTAS',
      1013: 'UTILIDADES DOMÉSTICAS',
      1014: 'JARDINAGEM LAZER',
      1015: 'AQUECIMENTO REFRIGERAÇÃO',
      1016: 'MADEIRAS COBERTURA',
      1018: 'FORA LINHA',
      1020: 'CONCRETO',
      1021: 'ENERGIA SOLAR',
      1023: 'OUTLET',
      1017: 'USO CONSUMO'
    },
    objSecoes: {
      'MATERIAL_BRUTO': 1001,
      'PISOS_REVESTIMENTOS': 1002,
      'QUÍMICOS_TINTAS_ACESSORIOS': 1003,
      'MATERIAL_ELETRICO': 1004,
      'ILUMINACAO': 1005,
      'HIDRAULICA': 1006,
      'PORTAS_JANELAS': 1007,
      'MATERIAL_SANITARIO': 1008,
      'LOUCAS': 1009,
      'MOVEIS_GABINETES': 1010,
      'FERRAGENS': 1011,
      'FERRAMENTAS': 1012,
      'UTILIDADES_DOMESTICAS': 1013,
      'JARDINAGEM_LAZER': 1014,
      'AQUECIMENTO_REFRIGERACAO': 1015,
      'MADEIRAS_COBERTURA': 1016,
      'FORA_LINHA': 1018,
      'CONCRETO': 1020,
      'ENERGIA_SOLAR': 1021,
      'OUTLET': 1023,
      'USO_CONSUMO': 1017,
    },
    lojasFiltradas: [],
    objLojas: {
      1: 'loja1',
      2: 'loja2',
      3: 'loja3',
      4: 'loja4',
      5: 'loja5',
      7: 'loja7',
      8: 'loja8',
      9: 'loja9',
      10: 'loja10',
      11: 'loja11',
      14: 'loja14',
      15: 'loja15',
      16: 'loja16',
      17: 'loja17',
      18: 'loja18',
      19: 'loja19',
      20: 'loja20',
      21: 'loja21',
      23: 'loja23',
      24: 'loja24',
      26: 'loja26',
      27: 'loja27',
      28: 'loja28',
      30: 'loja30',
      31: 'loja31',
      35: 'loja35',
      36: 'loja36',
      38: 'loja38',
      39: 'loja39',
      41: 'loja41',
    },
    objNomeLojas: {
      1: 'IVAIPORÃ',
      2: 'PITANGA',
      3: 'SJ. IVAÍ',
      4: 'CASTELO',
      5: 'CANDIDO',
      7: 'APUCARANA',
      8: 'J. ALEGRE',
      9: 'M. RIBAS',
      10: 'FAXINAL',
      11: 'C. MOURÃO',
      14: 'CIANORTE',
      15: 'PARANAVAÍ',
      16: 'TELÊMACO',
      17: 'RONCADOR',
      18: 'GUARAPUAVA',
      19: 'PALMITAL',
      20: 'IBIPORÃ',
      21: 'TURVO',
      23: 'S. MATEUS',
      24: 'SJ. TRIUNFO',
      26: 'PONTAL',
      27: 'MANDAGUARI',
      28: 'ASTORGA',
      30: 'CAMBÉ',
      31: 'RESERVA',
      35: 'ROLÂNDIA',
      36: 'MARINGÁ',
      38: 'LARANJEIRAS',
      39: 'CD ECOMMERCE',
      41: 'CASTELO',
    },
  }),
  computed: {
    ...mapState(["backendUrl", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
            v.idfilial != 6 &&
            v.idfilial != 12 &&
            v.idfilial != 13 &&
            v.idfilial != 25 &&
            v.idfilial != 29 &&
            v.idfilial != 33 &&
            v.idfilial != 37 &&
            v.idfilial != 40
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    handleRowClick(item) {
      this.carregando2 = true;
      if (this.expanded.includes(item)) {
        // Se o item já estiver expandido, remove-o da lista de expandidos
        this.listarProdutos(item);
        this.expanded = this.expanded.filter(i => i !== item);
      } else {
        // Caso contrário, define a lista de expandidos para conter apenas o item atual
        this.listarProdutos(item);
        this.expanded = [item];
      }
    },

    listarProdutos(item) {
      this.detalheProdutos = [];
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/notas/itens/listar`, {
          idpedido: item.idpedido,
        })
        .then((res) => {
          this.detalheProdutos = res.data;
          this.carregando2 = false;
        })
        .catch(() => {
          this.carregando2 = false;
        });
    },

    handleRowClick2(item) {
      this.carregando2 = true;
      if (this.expanded2.includes(item)) {
        // Se o item já estiver expandido, remove-o da lista de expandidos
        this.listarProdutosSecoes(item);
        this.expanded2 = this.expanded2.filter(i => i !== item);
      } else {
        // Caso contrário, define a lista de expandidos para conter apenas o item atual
        this.listarProdutosSecoes(item);
        this.expanded2 = [item];
      }
    },

    listarProdutosSecoes(item) {
      this.detalheMercadorias.produtos = [];
      return axios
        .post(`${this.backendUrl}dashboard/categoria/mercadoria/receber/produtos`, {
          periodo: this.busca.periodo.data,
          idempresa: this.idEmpresaDialog,
          idsecao: item.idsecao,
          idcomprador: this.idCompradorDialog,
        })
        .then((res) => {
          this.detalheMercadorias.produtos = res.data;
          this.carregando2 = false;
        })
        .catch(() => {
          this.carregando2 = false;
        });
    },

    listarSecoesCompradores(p_idcomprador) {
      this.detalheMercadorias.secoes = [];
      this.idCompradorDialog = p_idcomprador;
      this.expanded2 = [];
      this.carregando3 = true;
      return axios
        .post(`${this.backendUrl}dashboard/categoria/mercadoria/receber/secao`, {
          periodo: this.busca.periodo.data,
          idempresa: this.idEmpresaDialog,
          idcomprador: p_idcomprador,
        })
        .then((res) => {
          this.detalheMercadorias.secoes = res.data;
          this.carregando3 = false;
        })
        .catch(() => {
          this.carregando3 = false;
        });
    },

    carrega() {
      setTimeout(() => {
        //this.listarTotaisMetasDesconto();
        this.listarTotaisMetasPerDesconto(); //%
        this.listarTotaisMetasMes();
        this.listarTotaisMetasDia();
        this.listarTotaisMetasVariacao(); //%
        this.listarTotaisVariacoes();
        this.listarDataAtualizacao();
      }, 1000);
      setTimeout(() => {
        this.carregandoTotais = false;
        this.carregandoTotais2 = true;
        this.listarTotaisDevolucao(); // R$
        this.listarTotaisPerDevolucao(); //%
        this.listarTotaisDesconto(); // R$
        this.listarTotaisMargem();  // % mes passado e ano passado
        this.listarTotaisMargemMeta(); //%
        this.listarTotaisMargemAcumulada(); //%
        this.listarTotaisMargemxVenda(); //%
        this.listarTotaisMargemFatAnoAnt(); //%
        // --this.listarTotaisDiasEstoqueFuturo();
        // --this.listarTotaisDiasEstoqueReal();
        //this.listarTotaisObsoletos();
        this.listarTotaisEstoque();
        this.listarTotaisEntradasSaidasAvulsa();
        this.listarTotaisEstoqueAnoAnt();
        this.listarTotaisPedidos();
        this.listarTotaisMercadoriasRecebidas();
        this.listarTotaisTransferencia();
        this.listarTotaisItensAtivos();
        //this.listarTotaisEstoqueObsoletos();
        // --this.listarTotaisItensEncalhados();
        this.listarTotaisRupturaA();
        this.listarTotaisRupturaBC();
        this.listarTotaisGeralRuptura();
        this.listarTotaisRupturas();
        this.listarPercTotalRupturas();
        this.listarTotaisCurva();
        this.listarTotaisGeralCurva();
        this.listarTotaisCurvas();
      }, 2000);
      setTimeout(() => {
        this.carregandoTotais3 = false;
      }, 3000);
    },
    carrega2() {
      setTimeout(() => {
        //this.listarTotaisVendasLiquidaEmpresa();
        this.listarTotaisParticVendasEmpresa();
        //this.listarTotaisParticRegiaoVendasEmpresa();
        //this.listarTotaisVendasEmpresaLastyear();
        this.listarTotaisVendasEmpresaNominal();
        this.listarTotaisVendasEmpresaLastmonth();
        this.listarTotaisVendasEmpresaVariacao();
        this.listarDataAtualizacao();
      }, 1000);
      setTimeout(() => {
        this.carregandoTotais = false;
        this.carregandoTotais2 = true;
        this.listarTotaisEmpresaDevolucao();
        this.listarTotaisEmpresaPerDevolucao();
        //this.listarTotaisObsoletosEmpresa();
        this.listarTotaisEmpresaDesconto();
        this.listarTotaisEmpresaDescontoConcedido();
        this.listarTotaisEmpresaMargem();
        // this.listarTotaisEmpresaMargemMeta();
        // this.listarTotaisEmpresaDiasEstoqueFuturo();
        // this.listarTotaisEmpresaDiasEstoqueReal();
        this.listarTotaisEmpresaMargemLastyear();
        this.listarTotaisEmpresaEstoqueAnoAnt();
        this.listarTotaisEmpresaEntradasSaidasAvulsa();
        this.listarTotaisEmpresaEstoqueInicial();
        //this.listarTotaisEmpresaMovimentacoes();
        this.listarTotaisEmpresaEstoqueFinal();
        this.listarTotaisPedidosEmpresa();
        this.listarTotaisMercadoriasEmpresa();
        this.listarTotaisTransferenciaEmpresa();
        this.listarTotaisItensAtivosEmpresa();
        //this.listarTotaisEstoqueObsoletosEmpresa();
        // this.listarTotaisItensEncalhadosEmpresa();
      }, 2000);
      setTimeout(() => {
        this.listarTotaisRupturaAEmpresa();
        this.listarTotaisRupturaBCEmpresa();
        this.listarTotaisRupturaGeralEmpresa();
        this.listarTotaisCurvaAEmpresa();
        this.listarTotaisCurvaBCEmpresa();
        this.listarTotaisPerCurvaAEmpresa();
        this.listarTotaisPerCurvaBCEmpresa();
        this.listarTotaisCurvaGeralEmpresa();
      }, 3000);
      setTimeout(() => {
        this.carregandoTotais3 = false;
      }, 4000);
    },

    coresPerDesconto(nomeLoja) {
      let meta = (this.totalizadores.metasPerDesconto[0].metadesconto[nomeLoja])
      let concedido = (this.totalizadores.desconto[0].descontoconcedido[nomeLoja])

      if (meta > concedido) {
        return 'blue darken-2 white--text'
      } else if (meta == concedido) {
        return 'yellow accent-2'
      } else {
        return 'red white--text'
      }
    },

    limpaValores() {
      this.vendasLojas = [];
      this.vendasSecao = [];
      this.totalizadores = {
        vendas: {
          perclojas: [],
          percpartregionais: [],
          totaloja: []
        },
        vendasObsoletos: [],
        metasMes: [],
        metasDia: [],
        metasVariacao: [],
        variacoes: [],
        devolucao: [],
        perDevolucao: [],
        desconto: [],
        metasDesconto: [],
        metasPerDesconto: [],
        margem: [],
        margemAcumulada: [],
        margemMeta: [],
        margemVenda: null,
        margemFatAnoAnt: null,
        diasEstoqueFuturo: [],
        diasEstoqueReal: [],
        estoque: [],
        estoqueAnoAnt: [],
        entradasSaidasAvulsa: [],
        pedidos: [],
        itensAtivos: [],
        itensEncalhados: [],
        estoqueObsoletos: [],
        ruptura: [],
        rupturaA: [],
        rupturaBC: [],
        rupturaGeral: [],
        totalRupturas: [],
        curva: [],
        curvaGeral: [],
        totalCurvas: [],
        percRupturas:[],
        mercadoriasRecebidas: [],
        transferencias: [],
      };
      this.totalizadoresSecao = {
        vendas: [],
        vendasObsoletos: [],
        perparticSecao: [],
        perparticRegiao: [],
        variacoes: {
          vendaAnoAnt: [],
          variacaoNominal: [],
          vendaMesAnt: [],
          variacao: [],
          vendaLiquida: []
        },
        devolucao: [],
        perDevolucao: [],
        descontos: {
          desconto: [],
          perdesconto: []
        },
        margem: [],
        margemMeta: [],
        diasEstoqueFuturo: [],
        diasEstoqueReal: [],
        margemAnoAnt: [],
        estoqueAnoAnt: [],
        entradasSaidasAvulsa: [],
        estoqueInicial: [],
        movimentacoes: [],
        estoqueFinal: [],
        pedidos: [],
        mercadoriasRecebidas: [],
        itensAtivos: [],
        itensEncalhados: [],
        estoqueObsoletos: [],
        rupturaA: [],
        rupturaBC: [],
        rupturaGeral: [],
        curvaA: [],
        curvaBC: [],
        perCurvaA: [],
        perCurvaBC: [],
        curvaGeral: [],
        transferencias: [],
      };
    },

    coresMargem(per) {
      if (per <= 24.50) {
        return 'red white--text'
      } else if (per >= 24.51 && per <= 25.99) {
        return 'light green white--text'
      } else if (per > 26) {
        return 'blue darken-2 white--text'
      }
    },

    coresVarMeta(per) {
      if (per < 70) {
        return 'red white--text'
      } else if (per < 100) {
        return 'yellow accent-2'
      } else if (per > 100) {
        return 'blue darken-2 white--text'
      }
    },

    coresVarariacoes(per) {
      if (per < 0) {
        return 'red white--text'
      }
      else if (per == 0) {
        return 'yellow accent-2'
      } else {
        return 'blue darken-2 white--text'
      }
    },

    // validarRegiao(numero) {
    //   const numeroDigitado = parseInt(numero)
    //   let norte = (this.regionais[0].listaempresa.split(',').map(num => parseInt(num))).includes(numeroDigitado)
    //   let sul = (this.regionais[1].listaempresa.split(',').map(num => parseInt(num))).includes(numeroDigitado)
    //   let centro = (this.regionais[2].listaempresa.split(',').map(num => parseInt(num))).includes(numeroDigitado)
    //   if (norte) {
    //     return this.totalizadores.percpartregionais[0].percregional
    //   } else if (sul) {
    //     return this.totalizadores.percpartregionais[1].percregional
    //   } else if (centro) {
    //     return this.totalizadores.percpartregionais[2].percregional
    //   }
    //   return 0;
    // },
    validarNumero(numero) {
      if (this.filtroRegional != 0) {
        const numeroDigitado = parseInt(numero)
        const numeros = this.stringDeEmpresas.split(',').map(num => parseInt(num))
        let valida = numeros.includes(numeroDigitado)
        return valida;
      }
      return true;
    },
    validarSecao(nomeSecao) {

      const codSecoes = {
        1001: 'MATERIAL_BRUTO',
        1002: 'PISOS_REVESTIMENTOS',
        1003: 'QUÍMICOS_TINTAS_ACESSORIOS',
        1004: 'MATERIAL_ELETRICO',
        1005: 'ILUMINACAO',
        1006: 'HIDRAULICA',
        1007: 'PORTAS_JANELAS',
        1008: 'MATERIAL_SANITARIO',
        1009: 'LOUCAS',
        1010: 'MOVEIS_GABINETES',
        1011: 'FERRAGENS',
        1012: 'FERRAMENTAS',
        1013: 'UTILIDADES_DOMESTICAS',
        1014: 'JARDINAGEM_LAZER',
        1015: 'AQUECIMENTO_REFRIGERACAO',
        1016: 'MADEIRAS_COBERTURA',
        1018: 'FORA_LINHA',
        1020: 'CONCRETO',
        1021: 'ENERGIA_SOLAR',
        1023: 'OUTLET',
        1017: 'USO_CONSUMO'
      };

      // Converte o nome fornecido para maiúsculas
      const nomeSecaoUpper = nomeSecao.toUpperCase();

      // Itera sobre o objeto codSecoes para encontrar o código
      for (let codigo in codSecoes) {
        if (codSecoes[codigo] === nomeSecaoUpper) {
          return Number(codigo); // Retorna o código como número
        }
      }

      return null; // Retorna null se não encontrar

    },
    rowClass(item) {
      return this.expanded.includes(item) ? 'primary white--text c' : '';
    },
    rowClass2(item) {
      return this.expanded2.includes(item) ? 'primary white--text c' : '';
    },
    // Filtros
    listarPeriodos() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/periodo/listar`)
        .then((res) => {
          if (this.periodos.length > 0) {
            this.periodos = res.data;
          } else {
            this.periodos = res.data;
            this.busca.periodo = res.data[0];
          }
        });
    },
    listarSecoes() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/secao/listar`, {
          periodo: this.busca.periodo.data,
        })
        .then((res) => {
          this.secoes = res.data.map((s) => {
            return {
              idsecao: s.idsecao,
              secao: s.secao,
              descr: `${s.idsecao} - ${s.secao}`,
            };
          }).sort((a, b) => a.idsecao - b.idsecao);
          // this.busca.idsecao = this.secoes[2].idsecao;
        });
    },
    listarRegioes() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/regional/listar`,
          {
            periodo: this.busca.periodo.data,
          }
        )
        .then((res) => {
          this.regionais = res.data;
        });
    },
    listarVendas() {
      this.lojasFiltradas = this.lojas;
      this.carregandoTotais = true;
      this.carregando = true;
      this.carregandoTotais3 = true;
      this.limpaValores();
      if (!this.busca.idempresa) {
        this.listarTotaisVendas();
        this.carrega();
        return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/listar`, {
            idsecao: this.busca.idsecao || null,
            idregional: this.busca.idregional,
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            if (res.data != null) {
              this.filtroRegional = this.busca.idregional || 0;
              this.vendasLojas = res.data;
              if (this.filtroRegional != 0) {
                this.stringDeEmpresas = this.regionais.filter((v) => {
                  return v.idregional == this.filtroRegional
                })[0].listaempresa;
                this.lojasFiltradas = this.stringDeEmpresas.split(","); // ["hello", "world"]
              }
              this.carregando = false;
            } else {
              this.carrega();
              this.vendasLojas = [];
              this.carregando = false;
            }
          })
          .catch(() => {
            this.carregando = false;
          });
      } else {
        this.listarTotaisVendasEmpresa();
        this.carrega2();
        return axios
          .post(`${this.backendUrl}dashboard/categoria/venda/empresa/listar`, {
            idsecao: this.busca.idsecao || null,
            idempresa: this.busca.idempresa,
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            if (res.data != null) {
              this.vendasSecao = res.data.VendaEmpresa;
              this.carregando = false;
            } else {
              this.vendasSecao = [];
              this.carregando = false;
            }
          })
          .catch(() => {
            this.carregando = false;
          });
      }
    },

    // Detalhamento
    detalharRupturaEmpresa(p_idsecao, p_tipo, p_nomeempresa) {
      this.nomeEmpresaDialog = p_nomeempresa;
      this.tipoRuptura = p_tipo;
      this.dialogDetalheRuptura = true;
      this.detalheRuptura = [];
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/ruptura/itens/filial/listar`, {
          idempresa: this.busca.idempresa,
          tipo: p_tipo,
          idsecao: p_idsecao,

        })
        .then((res) => {
          this.detalheRuptura = res.data;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    // Detalhamento Ruptura
    detalharRuptura(p_idempresa, p_tipo, p_nomeempresa) {
      this.nomeEmpresaDialog = p_nomeempresa;
      this.tipoRuptura = p_tipo;
      this.dialogDetalheRuptura = true;
      this.detalheRuptura = [];
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/rupturaa/itens/listar`, {
          idempresa: p_idempresa,
          tipo: p_tipo,
          idsecao: this.busca.idsecao || null,
        })
        .then((res) => {
          this.detalheRuptura = res.data;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    // Detalhar produtos geral da curva
    detalharCurvaGeral(p_tipo){
      this.tipoRuptura = p_tipo;
      this.dialogDetalheRuptura = true;
      this.detalheRuptura = [];
      this.carregando3 = true;
      return axios
          .post(`${this.backendUrl}dashboard/onepage/curvageralitens`, {
            tipo: this.tipoRuptura,
          })
          .then((res) => {
            this.detalheRuptura = res.data.curvageralitens;
            this.carregando3 = false;
          })
          .catch(() => {
            console.log('Nao foi possivel converver os dados JSON')
            this.carregando3 = false;
          });
    },

    detalharPedidos(p_idempresa, p_nomeempresa) {
      this.detalhePedidos = [];
      this.nomeEmpresaDialog = p_nomeempresa;
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/notas/listar`, {
          idempresa: p_idempresa,
          idsecao: this.busca.idsecao || null,
        })
        .then((res) => {
          this.detalhePedidos = res.data;
          this.dialogDetalhePedidos = true;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },

    // Detalhamento de Estoque de Obsoletos
    detalharObsoletos(p_idempresa, p_nomeempresa) {
      this.detalheObsoletos = [];
      this.nomeEmpresaDialog = p_nomeempresa;
      return axios
        .post(`${this.backendUrl}dashboard/categoria/obsoleto/click/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: p_idempresa,
          idregional: this.busca.idregional || null,
          idsecao: this.busca.idsecao || null,
        })
        .then((res) => {
          this.detalheObsoletos = res.data;
          this.dialogDetalheObsoletos = true;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
          this.detalheObsoletos = [];
        });
    },

    //Detalhamento de Mercadorias recebidas - 1° nivel
    detalharMercadoriasComprador(p_idempresa, p_nomeempresa) {
      this.expanded2 = [];
      this.panel = [];
      this.detalheMercadorias.compradores = [];
      this.detalheProdutosSecoes = [];
      this.idEmpresaDialog = p_idempresa;
      this.nomeEmpresaDialog = p_nomeempresa;
      return axios
        .post(`${this.backendUrl}dashboard/categoria/mercadoria/receber/comprador`, {
          idempresa: p_idempresa,
          periodo: this.busca.periodo.data,
        })
        .then((res) => {
          this.detalheMercadorias.compradores = res.data;
          this.dialogDetalheMercadoriasRecebidas = true;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },

    // Totais lojas
    listarTotaisVendas() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/totalizadores/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.vendas = res.data;
        })
        .catch(() => {
        });
    },
    // listarTotaisObsoletos() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/categoria/obsoleto/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idregional: this.busca.idregional,
    //       idsecao: this.busca.idsecao || null,
    //     })
    //     .then((res) => {
    //       this.totalizadores.vendasObsoletos = res.data.principal;
    //     })
    //     .catch(() => {
    //     });
    // },
    // listarTotaisMetasDesconto() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/categoria/onepage/meta/desconto/real/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idsecao: this.busca.idsecao || null,
    //       idregional: this.busca.idregional,
    //     })
    //     .then((res) => {
    //       this.totalizadores.metasDesconto = res.data;
    //     })
    //     .catch(() => {
    //     });
    // },
    listarTotaisMetasPerDesconto() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/meta/desconto/perc/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.metasPerDesconto = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisMetasMes() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/meta/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.metasMes = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisMetasDia() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/meta/dia/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.metasDia = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisMetasVariacao() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/meta/variacao/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.metasVariacao = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisVariacoes() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/variacoes/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.variacoes = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisDevolucao() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/devolucao/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.devolucao = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisPerDevolucao() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/total/devolucao/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.perDevolucao = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisDesconto() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/desconto/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.desconto = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisMargem() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/margem/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.margem = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisMargemAcumulada() {
      return axios
          .post(`${this.backendUrl}dashboard/onepage/margemfaturamentoacumulada`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            this.totalizadores.margemAcumulada = JSON.parse(res.data.margemfaturadaacumulada);
          })
          .catch(() => {
            console.log('Erro ao converter dados do tipo JSON')
          });
    },
    listarTotaisMargemMeta() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/margemmeta`, {
          periodo: this.busca.periodo.data
        })
        .then((res) => {
          this.totalizadores.margemMeta = JSON.parse(res.data.margemmeta);
          console.log(this.totalizadores.margemMeta)
        })
        .catch(() => {
          console.log('Erro ao converter dados do tipo JSON')
        });
    },
    listarTotaisMargemxVenda(){
      return axios
          .post(`${this.backendUrl}dashboard/onepage/margemapoiodecisao`, {
            periodo: this.busca.periodo.data
          })
          .then((res) => {
            this.totalizadores.margemVenda = JSON.parse(res.data.margemapoiodecisao);
            console.log(this.totalizadores.margemVenda)
          })
          .catch(() => {
            console.log('Erro ao converter dados do tipo JSON')
          });
    },
    listarTotaisMargemFatAnoAnt(){
      return axios
          .post(`${this.backendUrl}dashboard/onepage/margemfaturadapassado`, {
            periodo: this.busca.periodo.data
          })
          .then((res) => {
            this.totalizadores.margemFatAnoAnt = JSON.parse(res.data.margemfatpassado);
          })
          .catch(() => {
            console.log('Erro ao converter dados do tipo JSON')
          });
    },
    listarTotaisDiasEstoqueFuturo() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/dias/estoque/futuro/geral/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.diasEstoqueFuturo = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisDiasEstoqueReal() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/dias/estoque/real/geral/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.diasEstoqueReal = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisEstoque() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/estoque/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.estoque = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisPedidos() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.pedidos = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisTransferencia() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/transferencia/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa || null,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional || null,
        })
        .then((res) => {
          this.totalizadores.transferencias = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisItensAtivos() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/itens/ativos/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.itensAtivos = res.data;
        })
        .catch(() => {
        });
    },
    // listarTotaisEstoqueObsoletos() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/onepage/itens/obsoletos/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idsecao: this.busca.idsecao || null,
    //       idregional: this.busca.idregional,
    //     })
    //     .then((res) => {
    //       this.totalizadores.estoqueObsoletos = res.data.principal;
    //     })
    //     .catch(() => {
    //     });
    // },
    listarTotaisItensEncalhados() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/itens/encalhados/geral/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.itensEncalhados = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisCurva() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/ruptura/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.curva = res.data;
          this.carregandoTotais2 = false;
        })
        .catch(() => {
          this.carregandoTotais2 = false;
        });
    },
    listarTotaisGeralCurva() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/total/curva/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.curvaGeral = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisRupturaA() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/rupturaa/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.rupturaA = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisRupturaBC() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/rupturabc/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.rupturaBC = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisGeralRuptura() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/total/ruptura/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.rupturaGeral = res.data;
        })
        .catch(() => {
        });
    },
    listarPercTotalRupturas(){
      return axios
          .post(`${this.backendUrl}dashboard/onepage/percrupturalgeral`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            this.totalizadores.percRupturas = JSON.parse(res.data.percruptura);
          })
          .catch(() => {
            console.log('Erro ao converter dados do tipo JSON')
          });
    },
    listarTotaisRupturas(){
      return axios
          .post(`${this.backendUrl}dashboard/onepage/rupturalgeral`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            this.totalizadores.totalRupturas = JSON.parse(res.data.totalruptura);
          })
          .catch(() => {
            console.log('Erro ao converter dados do tipo JSON')
          });
    },
    listarTotaisCurvas(){
      return axios
          .post(`${this.backendUrl}dashboard/onepage/curvageral`, {
            periodo: this.busca.periodo.data,
          })
          .then((res) => {
            this.totalizadores.totalCurvas = JSON.parse(res.data.totalcurva);
          })
          .catch(() => {
            console.log('Erro ao converter dados do tipo JSON')
          });
    },
    listarDataAtualizacao() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/dtinsercao/listar`)
        .then((res) => {
          this.dataUltAtualizacao = res.data;
        });
    },
    listarTotaisEstoqueAnoAnt() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/estoque/lastyear/listar`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.estoqueAnoAnt = res.data;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEntradasSaidasAvulsa() {
      return axios
        .post(`${this.backendUrl}dashboard/entradasaida/avulsa/lista`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadores.entradasSaidasAvulsa = res.data.principal;
        })
        .catch(() => {
        });
    },
    listarTotaisMercadoriasRecebidas() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/mercadoria/receber`, {
          periodo: this.busca.periodo.data,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional || null,
          idempresa: this.busca.idempresa || null
        })
        .then((res) => {
          this.totalizadores.mercadoriasRecebidas = res.data;
        })
        .catch(() => {
        });
    },


    // Totais secao
    listarTotaisVendasEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.vendas = res.data.TotalSecao;
        })
        .catch(() => {
        });
    },
    // listarTotaisObsoletosEmpresa() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/categoria/obsoleto/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idempresa: this.busca.idempresa,
    //     })
    //     .then((res) => {
    //       this.totalizadoresSecao.vendasObsoletos = res.data.secoes;
    //     })
    //     .catch(() => {
    //     });
    // },
    // listarTotaisVendasLiquidaEmpresa() {
    //   this.busca.idsecao = null;
    //   return axios
    //     .post(`${this.backendUrl}dashboard/categoria/total/venda/liquida/empresa/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idempresa: this.busca.idempresa,
    //     })
    //     .then((res) => {
    //       this.totalizadoresSecao.variacoes.vendaLiquida = res.data.TotalVendaLiquida;
    //     })
    //     .catch(() => {
    //     });
    // },
    listarTotaisParticVendasEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/partic/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.perparticSecao = res.data.TotalParticSecao;
        })
        .catch(() => {
        });
    },
    // listarTotaisParticRegiaoVendasEmpresa() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/partic/regional/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idempresa: this.busca.idempresa,
    //     })
    //     .then((res) => {
    //       this.totalizadoresSecao.perparticRegiao = res.data.TotalParticRegional;
    //     })
    //     .catch(() => {
    //     });
    // },
    // listarTotaisVendasEmpresaLastyear() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/categoria/total/venda/empresa/lastyear/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idempresa: this.busca.idempresa,
    //     })
    //     .then((res) => {
    //       this.totalizadoresSecao.variacoes.vendaAnoAnt = res.data.VendaLastYear;
    //       this.carregando = false;
    //     })
    //     .catch(() => {
    //       this.carregando = false;
    //     });
    // },
    listarTotaisVendasEmpresaNominal() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/total/venda/variacao/nominal/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.variacoes.variacaoNominal = res.data.VariacaoNominal;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisVendasEmpresaLastmonth() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/venda/empresa/lastmonth/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.variacoes.vendaMesAnt = res.data.VendaLastMonth;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisVendasEmpresaVariacao() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/total/venda/variacao/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.variacoes.variacao = res.data.Variacao;
          this.carregando = false;
          this.carregandoTotais = false;
        })
        .catch(() => {
          this.carregando = false;
          this.carregandoTotais = false;
        });
    },
    listarTotaisEmpresaDevolucao() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/venda/devolucao/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.devolucao = res.data.Devolucao;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaPerDevolucao() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/total/devolucao/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.perDevolucao = res.data.Devolucao;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaDesconto() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/venda/desconto/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.descontos.desconto = res.data.Desconto;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaDescontoConcedido() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/venda/desconto/concedido/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.descontos.perdesconto = res.data.Desconto;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaMargem() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/venda/margem/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.margem = res.data.Margem;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaMargemMeta() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/margem/meta/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.margemMeta = res.data;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaMargemLastyear() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/venda/margem/lastyear/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.margemAnoAnt = res.data.MargemLastYear;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaDiasEstoqueFuturo() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/dias/estoque/futuro/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.diasEstoqueFuturo = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisEmpresaDiasEstoqueReal() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/dias/estoque/real/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.diasEstoqueReal = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisEmpresaEstoqueAnoAnt() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/estoque/lastyear/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.estoqueAnoAnt = res.data.EstoqueLastYear;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisEmpresaEntradasSaidasAvulsa() {
      return axios
        .post(`${this.backendUrl}dashboard/entradasaida/avulsa/lista`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
          idsecao: this.busca.idsecao || null,
          idregional: this.busca.idregional,
        })
        .then((res) => {
          this.totalizadoresSecao.entradasSaidasAvulsa = res.data.secoes;
        })
        .catch(() => {
        });
    },

    listarTotaisEmpresaEstoqueInicial() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/estoque/inicial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.estoqueInicial = res.data.EstoqueInical;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    // listarTotaisEmpresaMovimentacoes() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/categoria/movimentacoes/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idempresa: this.busca.idempresa,
    //     })
    //     .then((res) => {
    //       this.totalizadoresSecao.movimentacoes = res.data.Movimentacoes;
    //       this.carregando = false;
    //     })
    //     .catch(() => {
    //       this.carregando = false;
    //     });
    // },
    listarTotaisEmpresaEstoqueFinal() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/estoque/final/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.estoqueFinal = res.data.EstoqueFinal;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisPedidosEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/pedidos/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.pedidos = res.data.Pedido;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisMercadoriasEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/mercadoria/receber`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa
        })
        .then((res) => {
          this.totalizadoresSecao.mercadoriasRecebidas = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisTransferenciaEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/transferencia/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa
        })
        .then((res) => {
          this.totalizadoresSecao.transferencias = res.data;
        })
        .catch(() => {
        });
    },
    listarTotaisItensAtivosEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/itens/ativos/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.itensAtivos = res.data;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    // listarTotaisEstoqueObsoletosEmpresa() {
    //   return axios
    //     .post(`${this.backendUrl}dashboard/onepage/itens/obsoletos/listar`, {
    //       periodo: this.busca.periodo.data,
    //       idsecao: this.busca.idsecao || null,
    //       idregional: this.busca.idregional,
    //       idempresa: this.busca.idempresa,
    //     })
    //     .then((res) => {
    //       this.totalizadoresSecao.estoqueObsoletos = res.data.secoes;
    //     })
    //     .catch(() => {
    //     });
    // },
    listarTotaisItensEncalhadosEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/onepage/itens/encalhados/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.itensEncalhados = res.data;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisCurvaAEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/curvaa/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.curvaA = res.data.CurvaA;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisCurvaBCEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/curvabc/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.curvaBC = res.data.CurvaBc;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisPerCurvaAEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/total/perccurvaa/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.perCurvaA = res.data.PercCurvaA;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisPerCurvaBCEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/perccurvabc/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.perCurvaBC = res.data.PercCurvabc;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisRupturaAEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/rupturaa/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.rupturaA = res.data.RupturaAFilial;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisRupturaBCEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/rupturabc/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.rupturaBC = res.data.TotalRupturaBcFilial;
          this.carregando = false;
        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisCurvaGeralEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/total/curva/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.curvaGeral = res.data.TotalCurvaFilialLoja;
          this.carregando = false;

        })
        .catch(() => {
          this.carregando = false;
        });
    },
    listarTotaisRupturaGeralEmpresa() {
      return axios
        .post(`${this.backendUrl}dashboard/categoria/onepage/total/ruptura/filial/listar`, {
          periodo: this.busca.periodo.data,
          idempresa: this.busca.idempresa,
        })
        .then((res) => {
          this.totalizadoresSecao.rupturaGeral = res.data.TotalRupturaFilialLoja;
          this.carregando = false;
          this.carregandoTotais2 = false;
        })
        .catch(() => {
          this.carregando = false;
          this.carregandoTotais2 = false;
        });
    },

    async init() {
      await this.listarPeriodos();
      await this.listarSecoes();
      await this.listarRegioes();
      await this.listarVendas();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.border-line-head th {
  height: 35px !important;
}

.border-line td {
  border-right: 1px solid #CCCCCC76;
  height: 28px !important;
}

.border-line-foot td {
  border-right: 0px solid #023449;
  height: 28px !important;
}

.fixed-column {
  position: sticky !important;
  left: 0 !important;
  z-index: 2 !important;
  background-color: #ECEFF1;
  border-right: 1px solid #023449 !important;
}

.fixed-column-header {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
  /*background-color: #4C92B0  !important;
  background-color: #395b84 !important;
  background-color: #01579B !important;*/
  background-color: #1D5092 !important;
  border-right: 1px solid #023449 !important;
}
.coluna-botao{
  position: sticky !important;
  left: 0 !important;
  z-index: 3; /* Aumente conforme necessário */
}
.fixed-botao-menu{
  position: absolute !important;
  top: 8px;
  right:0px;
  padding: 0px 0px 0px 0px !important;
  z-index: 1000 !important;
}

thead th {
  /*background-color: #1D607D  !important;
  background-color: #20456c !important;
  background-color: #0277BD !important;*/
  background-color: #1D5092 !important;
}

tfoot td {
  /*background-color: #B3E5FC;
  background-color: #b7d5f1;
  background-color: #E1F5FE;*/
  background-color: #D5D5D5 !important;
  border-left: 1px solid #B0BEC5;
}

.column-totais {
  background-color: #F2CB05;
}

/*.barra-total-left {
  //border-left: 1px solid #023449;
  //font-weight: bolder;
}*/

.barra-total-top td {
  height: 2px !important;
  background: #023449 !important;
}

.barra-total-top-final td {
  height: 2px !important;
  background: #023449 !important;
}
.fixed-button {
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
  background: #023449 !important;
}


.botao_detalhe {
  text-decoration: underline;
}

.botao_detalhe:hover {
  font-weight: bolder;
  text-decoration: none;
}

.header-pedido th {
  background-color: #888888 !important;
}

.botao_detalhe2 {
  text-decoration: underline;
}

.botao_detalhe2:hover {
  font-weight: bolder;
  text-decoration: none;
  padding-left: 18px !important;
}


.espaco-dinheiro {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  margin: 0px 0px 10px 0px;
}

.table_clicavel::v-deep .v-data-table__wrapper tr:hover {
  cursor: pointer;
}

::v-deep .cursor-normal {
  cursor: default !important;
  /* ou 'auto' */
}

.header th {
  background-color: #CFD8DC !important;
  height: 25px !important;
}

.body td {
  height: 20px !important;
  font-size: larger;
}

.foot td {
  height: 20px !important;
  font-weight: 500 !important;
  /*//background-color: #CFD8DC !important;*/
}
</style>